<ng-container *ngIf="job$ | async as job">
    <app-breadcrumbs-bar *ngIf="breadcrumbs" [breadcrumbs]="breadcrumbs"></app-breadcrumbs-bar>
    <div class="detail-header">
        <div class="detail-primary">
            <div class="detail-column">
                <span class="pill {{job.status | lowercase}}">
                    @if((job.status | lowercase) == "in_progress") {
                        Running
                    }
                    @else {
                        {{job.status | capitalizeFirstLetter}}
                    }
                </span>
            </div>
            <div class="detail-column">
                <div class="detail-item">
                    <h1 class="title">{{job.name}}</h1>
                    <p class="description"><span class="subtitle">{{job.description}}</span></p>
                </div>
            </div>
        </div>

        <div class="detail-secondary">
            <div class="detail-column">
                @if ((job.status | lowercase) == 'executing' || (job.status | lowercase) == 'queued' || (job.status | lowercase) == 'in_progress') {
                    <fluent-button appearance="accent"><div  class="execution"><i class="ms-Icon ms-Icon--CircleStopSolid"></i><span>Stop execution</span></div></fluent-button>
                }
                @else {
                    <fluent-button appearance="accent" (click)="onClickStartExecution(job.id)"><div class="execution"><i class="ms-Icon ms-Icon--Play"></i><span>Start execution</span></div></fluent-button>
                }
            </div>
            <div class="detail-column">
                <div class="detail-item">
                    <span>Project</span>
                    <span class="detail-item-font"><a [routerLink]="['/projects/' + job.projectId]">{{job.projectName}}</a></span>
                </div>
                <div class="detail-item">
                    <span>Project Variant</span>
                    <span class="detail-item-font"><a fragment="variants" [routerLink]="['/projects/' + job.projectId]">{{job.projectVariantName}}</a></span>
                </div>
            </div>
            <div class="detail-column">
                <div class="detail-item">
                    <span class="title">Created on</span>
                    <span>{{job.creationDate | date}}</span>
                </div>
                <div class="detail-item">
                    <span>Created by</span>
                    <span class="detail-item-font">{{job.creatorName}}</span>
                </div>
            </div>
            <div class="detail-column">
                <div class="detail-item">
                    <span class="title">Execution state</span>
                    @if (job.executionDate) {
                        <span>{{job.executionDate | date}}</span>
                    }
                    @else {
                        <span>-</span>
                    }

                </div>
                <div class="detail-item">
                    <span>Executed on</span>
                    <span class="detail-item-font">{{job.runnerName}}</span>
                </div>
            </div>
            <div class="detail-column">
                @if(job.url) {
                    <a href="{{job.url}}" class="icon"><i class="ms-Icon ms-Icon--OpenInNewTab" id="icon-issue"></i>Issue</a>
                }
                @else {
                    <a class="icon-disabled"><i class="ms-Icon ms-Icon--OpenInNewTab" id="icon-issue"></i>Issue</a>
                }

                @if((job.status | lowercase) == 'completed') {
                    <a (click)="downloadReport(job.id)" class="icon-report"><i class="ms-Icon ms-Icon--TextDocument" id="icon-report"></i>Report</a>
                }
                @else {
                    <a class="icon-disabled"><i class="ms-Icon ms-Icon--TextDocument" id="icon-report"></i>Report</a>
                }
                <!-- <a class="icon-results"><i class="ms-Icon ms-Icon--OpenInNewTab" id="icon-results"></i>Results</a> -->
                <a class="icon-results"><i class="ms-Icon" id="icon-results"></i></a>
            </div>
        </div>
    </div>

    <div class="details-container">
        <fluent-tabs activeid="model-versions">
            <fluent-tab id="model-versions">Model Versions</fluent-tab>
            <fluent-tab id="agent-outputs">Agent Output</fluent-tab>

            <fluent-tab-panel id="model-versions">
                <div *ngFor="let moduleType of moduleTypesMap$ | async | keyvalue; index as i">
                    <fluent-accordion>
                        <fluent-accordion-item>
                            <span slot="heading">{{moduleType.key | splitWord}} ({{(moduleType.value | keyvalue).length}})</span>
                            <div class="row" *ngFor="let moduleAssignment of (moduleType.value | keyvalue)">
                                <div class="row-item">
                                    <label>{{moduleAssignment.key}}</label>
                                </div>

                                <div class="row-item" *ngFor="let moduleVersion of moduleAssignment.value.sort(placeFMUFilesBeginning)">
                                    <div class="inner-row">
                                        <div class="inner-row-item">
                                            <div class="label-title-font">Version</div>
                                            <div class="label-version-icon">
                                                <label>{{ moduleVersion.version }}</label>
                                                <i style="opacity: 0;" class="ms-Icon ms-Icon--Download"></i>
                                            </div>
                                        </div>
                                        <div class="inner-row-item">
                                            @if (moduleType.key == 'PlantModel' || moduleType.key == 'ControllerModel') {
                                                @if(moduleVersion.name.endsWith('fmu')) {
                                                    <div class="label-title-font">FMU file</div>
                                                } @else {
                                                    <div class="label-title-font">Parameter file</div>
                                                }
                                            }
                                            @else {
                                                <div class="label-title-font">File</div>
                                            }

                                            <div class="label-version-icon">
                                                <i class="ms-Icon ms-Icon--Download"></i>
                                                <label class="label-overflow">{{moduleVersion.name | trimFileName}}</label>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </fluent-accordion-item>
                    </fluent-accordion>
                </div>
            </fluent-tab-panel>
            <fluent-tab-panel id="agent-outputs">
                @if ((job.status | lowercase) === 'queued' || (job.status | lowercase) === 'ready') {
                    <div class="empty-agent-output">
                        <img src="/assets/empty_agent_output.svg" class="empty-agent-output">
                        <h2>
                            <p class="empty-step-1">Job was not executed yet</p>
                            <p class="empty-step-2">After the execution starts, steps will be shown here.</p>
                        </h2>
                    </div>
                }
                @else if (steps$ | async) { 
                    <ul class="logs">
                        @for (step of steps$ | async; track $index) {
                            <li>
                                @if ((step.Status | lowercase) === 'pending') {
                                    <i class="ms-Icon ms-Icon--Processing"></i>
                                } @else if ((step.Status | lowercase) === 'running' || (step.Status | lowercase) === 'in_progress') {
                                    <fluent-progress-ring class="progress-ring"></fluent-progress-ring>
                                } @else if ((step.Status | lowercase) === 'completed') {
                                    <i class="ms-Icon ms-Icon--Accept"></i>
                                } @else {
                                    <i class="ms-Icon ms-Icon--StatusCircleQuestionMark"></i>
                                }
                                {{step.Name}}
                            </li>
                        }
                    </ul>
                }
            </fluent-tab-panel>
        </fluent-tabs>
    </div>
</ng-container>