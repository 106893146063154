import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-panel-text-field',
  standalone: true,
  imports: [],
  templateUrl: './panel-text-field.component.html',
  styleUrl: './panel-text-field.component.scss'
})
export class PanelTextFieldComponent {
  @Input() textFieldHeader: string = '';
  @Input() textFieldValue: string = '';
}
