import { AvlDashboardJobExecutionsModel } from "../models/dashboard";
import AvlJob from "../models/job";
import AvlProject from "../models/project";

export default class Utils {
    
    static transformAvlDashboardJobExecutionsModel(data: AvlDashboardJobExecutionsModel): any {
        let result: {
            labels: string[];
            datasets: {
              label: string;
              backgroundColor: string;
              data: number[];
              stack: string;
          }[];
         } = {
            labels: [],
            datasets: [
              {
                label: 'Completed',
                backgroundColor: '#2c9d21',
                data: [],
                stack: 'default'
              },
              {
                label: 'Cancelled',
                data: [],
                backgroundColor: '#b58726',
                stack: 'default'
              },
              {
                label: 'Failed',
                data: [],
                backgroundColor: '#f0605c',
                stack: 'default'
              },
              
            ],
          };
          for (let entry of data.executions) {
            result.labels.push(new Date(entry.executionDate).toLocaleString('en-US', { month: 'short', day: 'numeric' }));
            result.datasets.find(x => x.label === 'Completed')?.data.push(entry.completed);
            result.datasets.find(x => x.label === 'Failed')?.data.push(entry.failed);
            result.datasets.find(x => x.label === 'Cancelled')?.data.push(entry.cancelled);
          }
          return result;
    }

    private static sortByRecentJob(job_1: AvlJob, job_2: AvlJob): number {
        const timestamp1 = new Date(job_1.jobCreationDate).getTime();
        const timestamp2 = new Date(job_2.jobCreationDate).getTime();
        return timestamp2 - timestamp1;
    }

    private static sortByRecentProject(project_1: AvlProject, project_2: AvlProject): number {
        const timestamp1 = new Date(project_1.creationDate).getTime();
        const timestamp2 = new Date(project_2.creationDate).getTime();
        return timestamp2 - timestamp1;
    }

    static capitalizeFirstLetter(str: string): string {
        if (!str) {
            return '';
        }
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    static getRecentJob(data: AvlJob[]) {
        return data.sort(this.sortByRecentJob);
    }

    static getRecentProject(data: AvlProject[]) {
        return data.sort(this.sortByRecentProject);
    }

    static getCreationTimeDiff(creationDate: Date): string {
        const currentDate = new Date();
        const diffInMilliseconds = currentDate.getTime() - creationDate.getTime();
        const diffInMinutes = Math.floor(diffInMilliseconds / 60000);
        const diffInHours = Math.floor(diffInMilliseconds / 3600000);
        const diffInDays = Math.floor(diffInMilliseconds / 86400000);

        if (diffInMinutes < 60) {
            return `${diffInMinutes} minute(s)`;
        } else if (diffInHours < 24) {
            return `${diffInHours} hour(s)`;
        } else if (diffInDays < 7) {
            return `${diffInDays} day(s)`;
        } else if (diffInDays < 30) {
            return `${Math.floor(diffInDays / 7)} week(s)`;
        } else if (diffInDays < 365) {
            return `${Math.floor(diffInDays / 30)} month(s)`;
        } else {
            return `${Math.floor(diffInDays / 365)} year(s)`;
        }
    }

    static stringToDate(dateString: string): Date {
        return new Date(dateString);
    }
}