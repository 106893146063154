import { CUSTOM_ELEMENTS_SCHEMA, Component } from '@angular/core';
import { PageLoaderService } from '../../services/page-loader.service';
import { takeWhile } from 'rxjs';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-page-loader',
  standalone: true,
  imports: [ CommonModule ],
  templateUrl: './page-loader.component.html',
  styleUrl: './page-loader.component.scss',
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class PageLoaderComponent {

  constructor(private pageLoaderService: PageLoaderService) { }

  loading: boolean = false;
  message: string = "hallo";
  progressValue: number = -1;
  progressValueStyle: string = "";
  private _subscribed: boolean = true;

  ngOnInit(): void {
    this.subscribe();
  }

  private subscribe() {
    this.pageLoaderService.state
      .pipe(takeWhile(() => this._subscribed))
      .subscribe(loading => {
        this.loading = loading;
      });
    this.pageLoaderService.message
      .pipe(takeWhile(() => this._subscribed))
      .subscribe(message => {
        if (!!message) {
          this.message = message;
        }
      });
    this.pageLoaderService.progressValue
      .pipe(takeWhile(() => this._subscribed))
      .subscribe(progressValue => {
        if (!!progressValue) {
          this.progressValue = progressValue;
          this.progressValueStyle = `${progressValue}%`;
        }
      });
  }

  ngOnDestroy() {
    this._subscribed = false;
  }
}