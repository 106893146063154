import { CUSTOM_ELEMENTS_SCHEMA, Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, take, map, switchMap, combineLatest } from 'rxjs';
import { ProjectService } from '../../services/project.service';
import { CommonModule } from '@angular/common';
import AvlProjectDetail, { Repository } from '../../models/project-detail';
import AvlProjectVariant from '../../models/project-variant';
import { PanelTextFieldComponent } from '../../components/panel-text-field/panel-text-field.component';
import AvlRunnerLabel, { AvlLabel, AvlRunnerLabelCategory } from '../../models/runner-label';
import { JobService } from '../../services/job.service';
import { BreadcrumbElement, BreadcrumbsBarComponent } from '../../components/breadcrumbs-bar/breadcrumbs-bar.component';
import { SplitWordPipe } from '../../pipes/split-word';

@Component({
  selector: 'app-project-detail-page',
  standalone: true,
  imports: [
    BreadcrumbsBarComponent,
    CommonModule,
    PanelTextFieldComponent,
    SplitWordPipe
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  templateUrl: './project-detail-page.component.html',
  styleUrl: './project-detail-page.component.scss'
})
export class ProjectDetailPageComponent {
  breadcrumbs: BreadcrumbElement[] = [];
  project$: Observable<AvlProjectDetail> = this.activatedRoute.params.pipe(
    switchMap(params => {
      // this.projectService.projectsGetById(params['id']).subscribe(response => console.log(response));
      return this.projectService.projectsGetById(params['id'])}
      )
  );

  fluentTabActiveID$: Observable<string> = this.activatedRoute.fragment
    .pipe(
      map(fragment => fragment === 'variants' ? 'variants' : 'artifacts' )
  );

  numJobs$: Observable<number> = this.project$.pipe(
    switchMap(project => this.jobService.jobsGetAll('all').pipe(
      map(data => data.filter(job => job.projectId == project.id).length)
    ))
  )

  runnerLabelCategories$: Observable<AvlRunnerLabelCategory[]> = this.projectService.projectsRunnerlabelsGetAll(['infrastructure']);

  moduleTypeToRepositoriesMap$: Observable<Map<string, Repository[]>> = this.project$.pipe(
    map(projectDetail => {
      const groupedModuleTypesToRepositories: Map<string, Repository[]> = new Map();
      projectDetail.repositories.forEach(repo => {
        if (!groupedModuleTypesToRepositories.has(repo.type)) {
          groupedModuleTypesToRepositories.set(repo.type, [repo]);
        }
        else {
          groupedModuleTypesToRepositories.get(repo.type)?.push(repo)
        }
      })
      return groupedModuleTypesToRepositories;
    })
  );

  projectLabels$: Observable<AvlLabel[]> = this.project$.pipe(
    map(project => project.labels)
  );
  projectSoftwareAndAgentMap = this.projectGetSoftwareAndAgent();

  projectVariants$: Observable<AvlProjectVariant[]> = this.project$.pipe(
    map(project => project.variants)
  )

  projectRepositories$: Observable<Repository[]> = this.project$.pipe(
    map(projectDetail => projectDetail.repositories)
  );

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private projectService: ProjectService,
    private jobService: JobService) {
      this.project$.pipe(take(1))
      .subscribe(response => {
        this.breadcrumbs.push({item_name: 'Projects', relativePathFromRoot: '/projects'})
        this.breadcrumbs.push({item_name: response.name, relativePathFromRoot: null});
      })
    }

  projectGetSoftwareAndAgent(): Map<string, string> {
    const placeHolderSoftwareToProjectLabelMap: Map<string, string> = new Map();
    combineLatest([this.runnerLabelCategories$, this.project$])
    .pipe(
      map(([runnerLabelCategories, project]) => {
        for (let projLabels of project.labels) {
          for (let labelCategory of runnerLabelCategories) {
            for (let label of labelCategory.runnerLabels) {
              if (projLabels.id === label.id) {
                placeHolderSoftwareToProjectLabelMap.set(labelCategory.name, label.displayName);
              }
            }
          }
        }
      })
    ).subscribe();
     return placeHolderSoftwareToProjectLabelMap;
  }

  doesProjectVariantContainsModule(projectVariantId: string, moduleId: string, projectVariants: AvlProjectVariant[]): boolean {
    for (let projectVariant of projectVariants) {
      if (projectVariant.projectVariantId !== projectVariantId) {
        continue;
      }
      for (let module of projectVariant.projectVariantModules) {
        if (module.id === moduleId) {
          return true;
        }
      }
    }
    return false;
  }

  onClickCreateNewJob(projectId: string) {
    this.router.navigate(
      ['/jobs/new'],
      { queryParams: { projectId: projectId } }
    );
  }
}