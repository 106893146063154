<app-breadcrumbs-bar *ngIf="breadcrumbs" [breadcrumbs]="breadcrumbs"></app-breadcrumbs-bar>
<app-page-header title="New Job" [backButtonEnabled]="true"></app-page-header>

<div class="wizard">
    <div class="wizard-stepper">
        <ul>
            <li><a [class.active]="currentWizardStep == 0"><span>General Job Information</span></a></li>
            <li><a [class.active]="currentWizardStep == 1"><span>Module Versions</span></a></li>
            <li><a [class.active]="currentWizardStep == 2"><span>Execution</span></a></li>
        </ul>
    </div>

    <div class="wizard-content">
            @switch (currentWizardStep) {
                @case (0) {
                    <h3>Job Information</h3>
                    <p>Provide your DevOps project details, including the name, repository URL, and access token, to improve collaboration and version control.</p>

                    <div class="form-container" [formGroup]="jobCreationForm">
                        <!-- Project details -->
                        <h4>Project Details</h4>
                        <div class="form-columns" formGroupName="projectDetailsForm">
                            <div class="form-control">
                                <label>Project</label>
                                <fluent-combobox id="project" name="project" placeholder="Select a project" autocomplete="both">
                                    @for (project of projects$ | async; track $index) {

                                        @if(project.id == this.selectedProjectId) {
                                            <fluent-option (click)="onProjectChanged(project.id)" [value]="project.id" selected="true">{{ project.name }}</fluent-option>
                                        }
                                        @else {
                                            <fluent-option (click)="onProjectChanged(project.id)" [value]="project.id">{{ project.name }}</fluent-option>
                                        }
                                    }
                                </fluent-combobox>
                            </div>
                            <div class="form-control">
                                <label>Variant</label>
                                <fluent-combobox id="variant"
                                    [placeholder]="!selectedProjectId ? 'Select a project first' : 'Select a variant'"
                                    [disabled]="!selectedProjectId"
                                    autocomplete="both">
                                    @for (item of variants$ | async; track $index) {
                                        <fluent-option (click)="onProjectVariantChanged(item.projectVariantId)" [value]="item.projectVariantId">{{ item.projectVariantName }}</fluent-option>
                                    }
                                </fluent-combobox>
                            </div>
                        </div>
                        <!-- Job details -->
                        <h4>Job Details</h4>
                        <ng-container formGroupName="jobDetailsForm">
                            <div class="form-control">
                                <label>Name</label>
                                <fluent-text-field id="name" name="name" placeholder="Enter a name" formControlName="name" ngDefaultControl></fluent-text-field>
                            </div>
                            <div class="form-control">
                                <label>Description</label>
                                <fluent-text-area id="description" name="description" placeholder="Enter a description" formControlName="description" ngDefaultControl></fluent-text-area>
                            </div>
                            <div class="form-control">
                                <label>Issue Ticket</label>
                                <fluent-text-field id="ticket-id" placeholder="Enter the Issue tracking ticket" formControlName="meta" ngDefaultControl></fluent-text-field>
                            </div>
                        </ng-container>
                    </div>
                }
                @case (1) {
                    <h3>Module Versions</h3>
                    <p>Select the module versions and corresponding parameter files for your job.</p>

                    @for (type of moduleTypes | keyvalue; track $index) {
                        <fluent-accordion>
                            <fluent-accordion-item>

                                <span slot="heading">{{type.key | splitWord}} ({{type.value.size}})</span>

                                <div class="panel">
                                    @for (module of modules$ | async; track $index) {
                                        @if (module.type == type.key) {
                                            <div>
                                                <div class="form-columns">
                                                    <div class="form-control" id="module-name">
                                                        <label>{{module.name}}</label>
                                                    </div>
                                                    <div class="form-control">
                                                        @if (module.type == 'PlantModel' || module.type == 'ControllerModel') {
                                                            <label>FMU file</label>
                                                        }
                                                        @else {
                                                            <label>File</label>
                                                        }
                                                        <fluent-combobox (change)="setOptionsSelected(module.name)">
                                                            @for (option of module.options; track $index) {
                                                                @if (fileModuleVersionAssignment[module.id] == option.value){
                                                                    <fluent-option [value]="option.value" (click)="onFileModuleVersionChanged(module.id, option.value)" selected="true">{{option.label}}</fluent-option>
                                                                } @else {
                                                                    <fluent-option [value]="option.value" (click)="onFileModuleVersionChanged(module.id, option.value)">{{option.label}}</fluent-option>
                                                                }
                                                            }
                                                        </fluent-combobox>
                                                    </div>
                                                    @if (module.type == 'PlantModel' || module.type == 'ControllerModel') {
                                                        <div class="form-control">
                                                            <label>Parameter file</label>
                                                            <fluent-combobox (change)="setParamsSelected(module.name)">
                                                                @for (option of module.params; track $index) {
                                                                    @if (paramFileModuleVersionAssignment[module.id] == option.value) {
                                                                        <fluent-option [value]="option.value" (click)="onParamFileModuleVersionChanged(module.id, option.value)" selected="true">{{option.label}}</fluent-option>
                                                                    }
                                                                    @else {
                                                                        <fluent-option [value]="option.value" (click)="onParamFileModuleVersionChanged(module.id, option.value)">{{option.label}}</fluent-option>
                                                                    }
                                                                }
                                                            </fluent-combobox>
                                                        </div>
                                                    } @else {
                                                        <div class="form-control"></div>
                                                    }
                                                </div>
                                            </div>
                                        }
                                    }
                                </div>
                            </fluent-accordion-item>
                        </fluent-accordion>
                    }
                }
                @case (2) {
                    <h3>Execution</h3>
                    <p>Review the available infrastructure and choose the execution location for your job.</p>
                    <div>
                        <h4>Available Infrastructure</h4>
                        <div id="infrastructure-cards">
                            <fluent-card class="card-overview content-horizontal">
                                <div>
                                    <div class="card-title">
                                        HPCs
                                    </div>
                                    <div class="card-meta">
                                        <span class="metric">3</span>
                                        <span class="caption">of 5 available</span>
                                    </div>
                                </div>
                                <div class="p-2">
                                    <i class="ms-Icon ms-Icon--Thunderstorms large"></i>
                                </div>
                            </fluent-card>
                            <fluent-card class="card-overview content-horizontal">
                                <div>
                                    <div class="card-title">
                                        VMs
                                    </div>
                                    <div class="card-meta">
                                        <span class="metric">5</span>
                                        <span class="caption">of 10 available</span>
                                    </div>
                                </div>
                                <div class="p-2">
                                    <i class="ms-Icon ms-Icon--ConnectVirtualMachine large"></i>
                                </div>
                            </fluent-card>
                        </div>
                    </div>
                    <div [formGroup]="jobCreationForm">
                        <div formGroupName="infrastructureForm">
                            <h4>Execute on</h4>
                            @for (label of infrastructureRunnerLabels$ | async; track $index) {
                                <input type="radio" [value]="label.id" formControlName="type"/> {{label.displayName}}
                            }
                        </div>
                    </div>
                }
            }
    </div>

    <div class="wizard-actions">
        <fluent-button (click)="onCancelClicked()">Cancel</fluent-button>
        @if (currentWizardStep > 0) {
            <fluent-button (click)="wizardBack()">Back</fluent-button>
        }
        @if (currentWizardStep < 2) {
            <fluent-button appearance="accent" (click)="handleWizard()" [disabled]="!enableWizardNext()">Next</fluent-button>
        }
        @if (currentWizardStep == 2) {
            <fluent-button appearance="accent" (click)="submitForm()" [disabled]="!enableWizardNext()">Create Job</fluent-button>
        }
    </div>
</div>