<app-breadcrumbs-bar *ngIf="breadcrumbs" [breadcrumbs]="breadcrumbs"></app-breadcrumbs-bar>
<app-page-header title="Project: {{projectName}}" [backButtonEnabled]="true"></app-page-header>
<fluent-dialog [hidden]="!isAddNewVariantDialogOpen" id="addNewVariantDialog" trap-focus modal>
    <div [formGroup]="projectCreationForm" style="position: relative; height: 100%;">
        <div style="padding-left: 32px; padding-right: 32px;">
            <div formGroupName="newVariantDetailsForm">
                <h4>Add a variant</h4>
                <div class="form-control">
                    <label>Name</label>
                    <fluent-text-field formControlName="name" id="name" name="name" placeholder="New Variant"
                        formControlName="name" ngDefaultControl></fluent-text-field>
                </div>
                <div class="form-control">
                    <label>Description</label>
                    <fluent-text-area formControlName="description" id="description" name="description"
                        placeholder="Provide a description" formControlName="description"
                        ngDefaultControl></fluent-text-area>
                </div>
            </div>
        </div>
        <div class="dialog-actions">
            <fluent-button appearance="accent" (click)="onAddVariantClicked()">Create</fluent-button>
            <fluent-button appearance="outline" (click)="hideAddNewVariantDialog()">Cancel</fluent-button>
        </div>
    </div>
</fluent-dialog>


<div class="wizard">
    <div class="wizard-stepper">
        <ul>
            <li><a [class.active]="currentWizardStep == 0"><span>General Information</span></a></li>
            <li><a [class.active]="currentWizardStep == 1"><span>Software & Agents</span></a></li>
            <li><a [class.active]="currentWizardStep == 2"><span>Artifacts</span></a></li>
            <li><a [class.active]="currentWizardStep == 3"><span>Variants</span></a></li>
        </ul>
    </div>
    <div class="wizard-content">
        <div [hidden]="currentWizardStep !== 0" class="padding-top-left">
            <h3>General Project Information</h3>
            <p>Provide a name and a description for your project</p>

            <div class="form-container" [formGroup]="projectCreationForm">
                <div formGroupName="projectDetailsForm">
                    <div class="form-control">
                        <label>Name</label>
                        <fluent-text-field formControlName="name" id="name" name="name" placeholder="New Project"
                            formControlName="name" ngDefaultControl></fluent-text-field>
                    </div>
                    <div class="form-control">
                        <label>Description</label>
                        <fluent-text-area formControlName="description" id="description" name="description"
                            placeholder="Provide a description" formControlName="description"
                            ngDefaultControl></fluent-text-area>
                    </div>
                </div>
            </div>
        </div>
        <div [hidden]="currentWizardStep !== 1" class="padding-top-left">
            <h3>Software Information</h3>
            <p class="small-top-margin">Define which tools should be used within the project and specify agent
                requirements</p>
            <table class="combobox-table">
                <tr>
                    <th>&nbsp;</th>
                    <th>Tool</th>
                    <th>Version</th>
                </tr>
                @for (runnerLabelCategory of runnerLabelCategories$ | async; track $index) {
                <tr>
                    <td>{{runnerLabelCategory.name}}</td>
                    <td>
                        <fluent-combobox>
                            @for (runnerLabel of runnerLabelCategory.runnerLabels; track runnerLabel) {
                            <fluent-option
                                (click)="onRunnerLabelChanged(runnerLabelCategory.id, runnerLabel.id, runnerLabel.name)"
                                [value]="runnerLabel.id">{{runnerLabel.displayName}}</fluent-option>
                            }
                        </fluent-combobox>
                    </td>
                    <td>
                        <fluent-combobox [disabled]="!isRunnerCategorySet(runnerLabelCategory.id)"
                            [placeholder]="!isRunnerCategorySet(runnerLabelCategory.id) ? 'Select Tool first' : 'Select a version'">
                            <fluent-option value="latest">latest</fluent-option>
                        </fluent-combobox>
                    </td>
                </tr>
                }
            </table>
        </div>
        <div [hidden]="currentWizardStep !== 2" [formGroup]="projectCreationForm">
            <div formGroupName="repositoriesDetailsForm">
                <div formArrayName="repositories">
                    <div class="flex-container">
                        <div class="flex-child">
                            <div class="padding-top-left">
                                <h3>Artifact repositories ({{repositories.controls.length}})</h3>
                            </div>
                            <fluent-accordion class="condensed" dir="rtl">
                                @for (type of moduleTypes$ | async; track $index) {
                                <div class="accordion-item-wrapper">
                                    <fluent-accordion-item class="condensed" style="stroke-width: 0;">
                                        <span slot="heading">{{type.name}}
                                            ({{numOfReposPerModuleTypeId(type.id)}})</span>
                                        <div class="panel">
                                            <fluent-listbox class="full-width" aria-labelledby="lb_label">
                                                @for (repo of repositories.controls; track $index) {
                                                @if (repo.value.moduleType === type.id) {
                                                <fluent-option value="fn">
                                                    {{repo.value.moduleName}}
                                                </fluent-option>
                                                }
                                                }
                                            </fluent-listbox>
                                        </div>
                                    </fluent-accordion-item>
                                </div>
                                }
                            </fluent-accordion>
                        </div>
                        <div class="flex-child">
                            <!-- <h3>Details</h3> -->
                            <div class="padding-top-left">
                                <h3>Details</h3>
                                <br />
                                <fluent-tabs>
                                    <fluent-tab label="modelVersions">Model Versions</fluent-tab>
                                    <fluent-tab label="testData">Test Data</fluent-tab>
                                    <fluent-tab-panel id="modelVersions">
                                        @for (type of moduleTypes$ | async; track $index) {
                                        <fluent-accordion>
                                            <fluent-accordion-item>
                                                <span slot="end">
                                                    <fluent-button appearance="outline"
                                                        (click)="onClickAddRepository(type.id)">Add</fluent-button>
                                                </span>
                                                <span slot="heading">{{type.name}}
                                                    ({{numOfReposPerModuleTypeId(type.id)}})</span>
                                                <div class="panel">
                                                    @for (repo of repositories.controls; track $index) {
                                                    <div [formGroupName]="$index">
                                                        @if (repo.value.moduleType === type.id) {
                                                        <div class="enumeration-entity">
                                                            <fluent-text-field formControlName="moduleName"
                                                                placeholder="Name"
                                                                ngDefaultControl>Name</fluent-text-field>
                                                            <fluent-text-field formControlName="repositoryUrl"
                                                                id="repoUrl" name="repoUrl"
                                                                placeholder="https://github.com/..."
                                                                ngDefaultControl>Repository URL</fluent-text-field>
                                                            <fluent-text-field formControlName="repositoryAccessToken"
                                                                id="accessToken" name="accessToken"
                                                                placeholder="<token>" ngDefaultControl>Access
                                                                Token</fluent-text-field>
                                                        </div>
                                                        }
                                                    </div>
                                                    }
                                                </div>
                                            </fluent-accordion-item>
                                        </fluent-accordion>
                                        }
                                    </fluent-tab-panel>
                                    <fluent-tab-panel id="testData">
                                        something
                                    </fluent-tab-panel>
                                </fluent-tabs>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div [hidden]="currentWizardStep !== 3" [formGroup]="projectCreationForm">
            <div formGroupName="repositoriesDetailsForm">
                <div class="flex-container">
                    <div class="flex-child">
                        <div class="padding-top-left">
                            <table style="width: 100%;">
                                <tr>
                                    <td>
                                        <h3>Variants ({{variants.controls.length}})</h3>
                                        <br />
                                    </td>
                                    <td style="align-items: end; display: flex; padding-top: 8px;">
                                        <i class="ms-Icon ms-Icon--CircleAdditionSolid large icon-button"
                                            (click)="showAddNewVariantDialog()"></i>
                                        <i class="ms-Icon ms-Icon--Delete large icon-button"
                                            (click)="onDeleteVariantClicked()"></i>
                                        <i class="ms-Icon ms-Icon--Edit large icon-button"
                                            (click)="onEditVariantClicked()"></i>
                                        <br />
                                        <!-- <fluent-button (click)="showAddNewVariantDialog()">Add</fluent-button>
                                            <fluent-button (click)="onDeleteVariantClicked('x')">Delete</fluent-button>
                                            <fluent-button (click)="onEditVariantClicked('x')">Edit</fluent-button> -->
                                    </td>
                                </tr>
                            </table>
                        </div>
                        @for (variant of variants.controls; track $index) {
                        <div class="card" [class.card-selected]="variant.value.id === selectedVariantId"
                            (click)="onVariantSelectedClicked(variant.value.id)">
                            <div class="card-header">
                                {{variant.value.name}}
                            </div>
                            <div class="card-body">
                                {{variant.value.description}}
                            </div>
                        </div>
                        }
                    </div>
                    <div class="flex-child">
                        <div class="padding-top-left">
                            <h3>Variant configuration</h3>
                            <br />
                            <table class="checkbox-table">
                                <tr>
                                    <th>Model</th>
                                    @for (variant of variants.controls; track $index) {
                                    <th>{{variant.value.name}}</th>
                                    }
                                </tr>
                                @for (repo of repositories.controls; track repo) {
                                <tr>
                                    <td>{{repo.value.moduleName}}</td>
                                    @for (variant of repo.value.variants; track $index) {
                                    <th>
                                        <fluent-checkbox [checked]="variant.selected"
                                            (change)="cbxChange(repo.value.moduleId, variant.variantId, '')"></fluent-checkbox>
                                    </th>
                                    }
                                </tr>
                                }
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="wizard-actions">
        <fluent-button (click)="onCancelClicked()">Cancel</fluent-button>
        @if (currentWizardStep > 0) {
            <fluent-button (click)="wizardBack()">Back</fluent-button>
        }
        @if (currentWizardStep < 3) { 
            <fluent-button appearance="accent" (click)="handleWizard()" [disabled]="!enableWizardNext()">Next</fluent-button>
        }
        @if (currentWizardStep == 3) {
            <fluent-button appearance="accent" (click)="submitForm()" [disabled]="!enableWizardNext()">Create Project</fluent-button>
        }
    </div>
</div>