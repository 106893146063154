import { CUSTOM_ELEMENTS_SCHEMA, Component, HostListener, Injector, OnInit } from '@angular/core';
import { from } from 'rxjs';
import { environment } from '../../environments/environment';
import { MsalService } from '@azure/msal-angular';
import { KeycloakService } from 'keycloak-angular';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-nav-bar',
  standalone: true,
  imports: [],
  templateUrl: './nav-bar.component.html',
  styleUrl: './nav-bar.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class NavBarComponent implements OnInit {

  public showUserMenu: boolean = false;
  public userImageUrl: string = '/assets/avatar-placeholder.jpg'; // Default image

  constructor(private injector: Injector, private http: HttpClient) { }
  
  ngOnInit(): void {
    if (environment.azureAdClientId) {
      const msalService: MsalService = this.injector.get(MsalService);
      msalService.initialize().subscribe(() => {
        msalService.instance.setActiveAccount(msalService.instance.getAllAccounts()[0]);
        msalService.instance.acquireTokenSilent({ scopes: ['User.Read']})
        .then(response => {
          this.http.get('https://graph.microsoft.com/v1.0/me/photo/$value', { responseType: 'blob' })
          .subscribe(image => {
            let reader = new FileReader();
            reader.addEventListener("load", () => {
              this.userImageUrl = reader.result as string;
            }, false);
              if (image) {
                reader.readAsDataURL(image);
              }
            })
        });
      });
    }
  }

  public btnLogoutClick($event: any) {
    this.showUserMenu = false;
    if (environment.azureAdClientId) {
      const msalService: MsalService = this.injector.get(MsalService);
      msalService.logout().subscribe(() => console.log("Logged out using MsalService."));
    } else {
      const keycloakService: KeycloakService = this.injector.get(KeycloakService);
      from(keycloakService.logout()).subscribe(() => console.log("Logged out using KeycloakService."));
    }
  }

  public btnUserMenuClick($event: any) {
    this.showUserMenu = !this.showUserMenu;
    $event.stopPropagation();
  }

  @HostListener('document:click', ['$event'])
  handlerFunction(e: MouseEvent) {
    this.showUserMenu = false;
  }
}
