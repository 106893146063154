import { Injectable } from "@angular/core";
import { BaseResourceService } from "./base-resource.service";
import { Observable, map } from "rxjs";
import { AvlModuleType, AvlModuleTypes } from "../models/module-type";

@Injectable({
    providedIn: 'root'
})
export class RepositorySerice extends BaseResourceService {
    private readonly _resourceEndpoint: string = 'api/repositories';

    public repositoriesModuletypesGetAll(): Observable<AvlModuleType[]> {
        return this.get<AvlModuleTypes>(`${this._resourceEndpoint}/moduletypes/getall`)
            .pipe(map(response => response.moduleTypes));
    }
}