import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'trimFileName',
  standalone: true
})
export class TrimFileNamePipe implements PipeTransform {
  transform(value: string): string {
    const segments = value.split('/');
    return segments.length > 0 ? segments[segments.length - 1] : value;
  }
}