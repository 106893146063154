import { Injectable } from "@angular/core";
import { BaseResourceService } from "./base-resource.service";
import { Observable } from "rxjs";
import { AvlUser } from "../models/admin";

@Injectable({
    providedIn: 'root'
})
export class AdminService extends BaseResourceService {
    private readonly _resourceEndpoint: string = 'api/admin';

    public upsertUser(): Observable<AvlUser> {
        return this.get<AvlUser>(`${this._resourceEndpoint}/upsertUser`);
    }
}