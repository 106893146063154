import { Injectable } from "@angular/core";
import { BaseResourceService } from "./base-resource.service";
import { Observable } from "rxjs";
import { AvlDashboardJobExecutionsModel, AvlDashboardJobsModel, AvlDashboardMetaModel } from "../models/dashboard";

@Injectable({
    providedIn: 'root'
})
export class DashboardService extends BaseResourceService {
    private readonly _resourceEndpoint: string = 'api/dashboard';

    public dashboardLatestJobExecutions(lastXDays: number): Observable<AvlDashboardJobExecutionsModel> {
        return this.get<AvlDashboardJobExecutionsModel>(`${this._resourceEndpoint}/jobexecutions?lastXdays=${lastXDays}`);
    }

    public dashboardMeta(): Observable<AvlDashboardMetaModel> {
        return this.get<AvlDashboardMetaModel>(`${this._resourceEndpoint}/meta`);
    }

    public dashboardJobs(creatorId?: string, fromDate?: string, toDate?: string): Observable<AvlDashboardJobsModel> {
        return this.get<AvlDashboardJobsModel>(`${this._resourceEndpoint}/jobs`);
    }
}