import { Routes } from '@angular/router';

import { DashboardPageComponent } from './pages/dashboard-page/dashboard-page.component';
import { JobCreationPageComponent } from './pages/job-creation-page/job-creation-page.component';
import { AuthGuard } from './utils/app.guard'
import { JobListPageComponent } from './pages/job-list-page/job-list-page.component';
import { JobDetailPageComponent } from './pages/job-detail-page/job-detail-page.component';
import { ProjectListPageComponent } from './pages/project-list-page/project-list-page.component';
import { ProjectDetailPageComponent } from './pages/project-detail-page/project-detail-page.component';
import { ProjectCreationPageComponent } from './pages/project-creation-page/project-creation-page.component';
import { MsalGuard } from '@azure/msal-angular';
import { environment } from './environments/environment';


export const routes: Routes = [
    {
        path: '',
        canActivate: [ environment.azureAdClientId ? MsalGuard : AuthGuard ],
        component: DashboardPageComponent
    },
    {
        path: 'projects',
        canActivate: [ environment.azureAdClientId ? MsalGuard : AuthGuard ],
        component: ProjectListPageComponent
    },
    {
        path: 'projects/new',
        canActivate: [ environment.azureAdClientId ? MsalGuard : AuthGuard ],
        component: ProjectCreationPageComponent
    },
    {
        path: 'projects/:id',
        canActivate: [ environment.azureAdClientId ? MsalGuard : AuthGuard ],
        component: ProjectDetailPageComponent
    },
    {
        path: 'jobs',
        canActivate: [ environment.azureAdClientId ? MsalGuard : AuthGuard ],
        component: JobListPageComponent
    },
    {
        path: 'jobs/new',
        canActivate: [ environment.azureAdClientId ? MsalGuard : AuthGuard ],
        component: JobCreationPageComponent,
    },
    {
        path: 'jobs/:id',
        canActivate: [ environment.azureAdClientId ? MsalGuard : AuthGuard ],
        component: JobDetailPageComponent
    },
    {
        path: 'dashboard',
        canActivate: [ environment.azureAdClientId ? MsalGuard : AuthGuard ],
        component: DashboardPageComponent
    }
];
