import { CUSTOM_ELEMENTS_SCHEMA, Component, OnInit } from '@angular/core';
import { BreadcrumbsBarComponent, BreadcrumbElement } from '../../components/breadcrumbs-bar/breadcrumbs-bar.component';
import { PageHeaderComponent } from '../../components/page-header/page-header.component';
import { FormArray, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { JobService } from '../../services/job.service';
import { ProjectService } from '../../services/project.service';
import { CommonModule } from '@angular/common';
import { Observable, tap, map, take } from 'rxjs';
import AvlProject from '../../models/project';
import AvlProjectVariant, { AvlProjectVariantModule } from '../../models/project-variant';
import { ActivatedRoute, Router, UrlHandlingStrategy } from '@angular/router';
import { SplitWordPipe } from '../../pipes/split-word';
import AvlAllRunnerLabels, { AvlLabel, AvlRunnerLabelCategory } from '../../models/runner-label';
import { RepositorySerice } from '../../services/repository.service';
import { AvlModuleType } from '../../models/module-type';
import { RepositoryCreationModel } from '../../models/repository-creation-model';
import { v4 as uuidv4 } from 'uuid';
import { VariantCreationModel } from '../../models/variant-creation-model';
import RegexPatterns from '../../utils/regexpatterns';

enum WizardStep {
    ProjectFormDetails = 0,
    SoftwareAgents = 1,
    Artifacts = 2,
    Variants = 3
  }

@Component({
    selector: 'app-job-creation-page',
    standalone: true,
    imports: [
      CommonModule,
      ReactiveFormsModule,
      BreadcrumbsBarComponent, PageHeaderComponent,
      SplitWordPipe
    ],
    providers: [
      JobService, ProjectService
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA],
    templateUrl: './project-creation-page.component.html',
    styleUrl: './project-creation-page.component.scss'
  })
  export class ProjectCreationPageComponent {
    currentWizardStep: WizardStep = WizardStep.ProjectFormDetails;
    areRequiredOptionsSelected: boolean = false;
    breadcrumbs: BreadcrumbElement[] = [
        {item_name: 'Projects', relativePathFromRoot: '/projects'},
        {item_name: 'New Project', relativePathFromRoot: null}
    ]

    runnerLabelCategories$: Observable<AvlRunnerLabelCategory[]> = this.projectService.projectsRunnerlabelsGetAll(['infrastructure']);
    moduleTypes$: Observable<AvlModuleType[]> = this.repositoryService.repositoriesModuletypesGetAll();

    constructor(
        private jobService: JobService,
        private projectService: ProjectService,
        private repositoryService: RepositorySerice,
        private router: Router,
        private route: ActivatedRoute
    ) { }

    isAddNewVariantDialogOpen: boolean = false;

    showAddNewVariantDialog() {
      this.isAddNewVariantDialogOpen = true;
    }

    hideAddNewVariantDialog() {
      this.isAddNewVariantDialogOpen = false;
    }

    projectCreationForm = new FormGroup({
      projectDetailsForm: new FormGroup({
        name: new FormControl('', [Validators.required, Validators.pattern(/\S/)]),
        description: new FormControl(''),
      }),
      softwareInformationForm: new FormGroup({
        runnerLabels: new FormArray([])
      }),
      repositoriesDetailsForm: new FormGroup({
        // projectId: new FormControl(''),
        // variantId: new FormControl(''),
        repositories: new FormArray([]),
      }),
      variantsDetailsForm: new FormGroup({
        variants: new FormArray([]),
      }),
      newVariantDetailsForm: new FormGroup({
        name: new FormControl(''),
        description: new FormControl(''),
      })
    });

    numOfReposPerModuleTypeId(moduleTypeId: string): number {
      return this.repositories.controls.filter((repo) => (repo as FormGroup).get('moduleType')?.value === moduleTypeId).length;
    }

    get projectName(): string {
      let projName =  this.projectCreationForm.get('projectDetailsForm')?.get('name')?.value;
      if (projName) {
        return projName;
      }
      return "New Project";
    }

    get repositories(): FormArray { 
      return (this.projectCreationForm.get('repositoriesDetailsForm') as FormGroup).get('repositories') as FormArray;
    }

    get variants(): FormArray {
      let vari = (this.projectCreationForm.get('variantsDetailsForm') as FormGroup).get('variants') as FormArray;
      console.log(vari);
      return vari;
    }

    cbxChange(repoId: any, variantId: any, checked: any) {
      for (let repo of this.repositories.controls) {
        if (repoId === repo.get("moduleId")?.value) {
          let variants = (repo.get("variants") as FormArray).controls;
          for (let variant of variants) {
            if (variant.get("variantId")?.value == variantId) {
              variant.get("selected")?.setValue(!variant.get("selected")?.value);
            }
          }
        }
      }
    }

    onClickAddRepository(moduleType: string) {
      const repositoriesArray = this.projectCreationForm?.get('repositoriesDetailsForm')?.get('repositories') as FormArray;
      repositoriesArray.push(
        new FormGroup({
          moduleId: new FormControl(uuidv4()),
          moduleType: new FormControl(moduleType),
          moduleName: new FormControl('', [Validators.required, Validators.pattern(/\S/)]),
          repositoryUrl: new FormControl('', [Validators.required, Validators.pattern(RegexPatterns.ValidUrl)]),
          repositoryAccessToken: new FormControl('', [Validators.required, Validators.pattern(/\S/)]),
          variants: new FormArray([])
        })
      );

      // begin: if user goes back in form and add a new repository, we need to add the new variants to the existing repositories
      for (let repo of this.repositories.controls) {
        for (let variant of this.variants.controls) {
          if (repo.get('variants')?.value.find((v: any) => v.variantId === variant.get('id')?.value)) {
            continue;
          }
          (repo.get('variants') as FormArray).push(new FormGroup({
            variantId: new FormControl(variant.get('id')?.value),
            name: new FormControl(variant.get('name')?.value),
            selected: new FormControl(false)
          }));
        }
      }
      // end
    }

    isRunnerCategorySet(runnerLabelCategory: string): boolean {
      const softwareInformationForm = this.projectCreationForm.get('softwareInformationForm') as FormGroup;
      const runnerLabels = softwareInformationForm.get('runnerLabels') as FormArray;
      return runnerLabels.controls.some(control => control?.value['runnerLabelCategory'] === runnerLabelCategory);
    }

    onRunnerLabelChanged(runnerLabelCategory: string, runnerLabelId: string, runnerLabelName: string) {
      console.log(`RunnerLabelCategory ${runnerLabelId} -> RunnerLabel ${runnerLabelId}`);
      
      const softwareInformationForm = this.projectCreationForm.get('softwareInformationForm') as FormGroup;
      const runnerLabels = softwareInformationForm.get('runnerLabels') as FormArray;
    
      let runnerLabelControl = runnerLabels.controls.find(control => {
        console.log('control runnerLabelCategory:', control?.value['runnerLabelCategory']);
        return control?.value['runnerLabelCategory'] === runnerLabelCategory;
      });
      if (runnerLabelControl) {
        // If a FormControl with the given runnerLabelCategory exists, update its runnerLabelId
        runnerLabelControl?.get('runnerLabelId')?.setValue(runnerLabelId);
      } else {
        // Otherwise, add a new FormControl to the FormArray
        runnerLabels.push(new FormGroup({
          runnerLabelCategory: new FormControl(runnerLabelCategory),
          runnerLabelId: new FormControl(runnerLabelId),
          runnerLabelName: new FormControl(runnerLabelName),
          runnerLabelVersion: new FormControl(''),
        }));
      }
    }

    handleWizard() {
      console.log('Wizard form state', this.projectCreationForm.value);
      if (this.currentWizardStep < WizardStep.Variants) {
        this.currentWizardStep++;
        return;
      }
  
      this.submitForm();
    }
  
    wizardBack() {
      if (this.currentWizardStep > WizardStep.ProjectFormDetails) {
        this.currentWizardStep--;
      }
    }

    // This function enables the next button by toggling [disabled] ="!enableWizard()"" in the html.
    enableWizardNext(): boolean {
      switch (this.currentWizardStep) {
        case WizardStep.ProjectFormDetails:
          return this.projectCreationForm?.get("projectDetailsForm")?.valid === true;
        case WizardStep.SoftwareAgents:
          // todo if tool selected, it should also have a version selected. But it is not required to select a tool at all
          //  let runnerLabels = this.projectCreationForm?.get("softwareInformationForm")?.get("runnerLabels") as FormArray;
          //  for (let runnerLabel of runnerLabels.controls) {
          //    if (runnerLabel.get('runnerLabelVersion')?.value) {
          //      return true;
          //    }
          //  }
          return true;
        case WizardStep.Artifacts:
          return (this.projectCreationForm?.get('repositoriesDetailsForm')?.get("repositories") as FormArray)?.length > 0 
          && this.projectCreationForm?.get('repositoriesDetailsForm')?.valid === true;
        case WizardStep.Variants:
          for (let repo of this.repositories.controls) {
            for (let variant of (repo.get('variants') as FormArray).controls) {
              if ((variant as FormGroup)?.get("selected")?.value === true) {
                return true;
              }
            }
          }
      }
      return false;
    }

    onCancelClicked() {
      this.router.navigate(['/projects']);
    }

    submitForm() {
      let variantNames = new Set();
      let variants: VariantCreationModel[] = [];

      let runnerLabels: string[] = [];
      for (let runnerLabel of (this.projectCreationForm?.get("softwareInformationForm")?.get("runnerLabels") as FormArray)?.controls) {
        runnerLabels.push(runnerLabel.get("runnerLabelId")?.value);
      }
      
      let repositories: RepositoryCreationModel[] = [];
      for (let repository of this.repositories.controls) {
        let repoFormGroup = repository as FormGroup;
        repositories.push({
          moduleName: repoFormGroup?.get('moduleName')?.value ?? "",
          moduleType: repoFormGroup?.get('moduleType')?.value ?? "",
          repositoryUrl: repoFormGroup?.get('repositoryUrl')?.value ?? "",
          repositoryAccessToken: repoFormGroup?.get('repositoryAccessToken')?.value ?? ""
        });
        for (let variant of repository.get('variants')?.value) {
          let variantId = variant["variantId"];
          let variantName = variant["name"];
          let variantDescription = this.getVariantById(variantId)?.get("description")?.value;
          let selected = variant["selected"];
      
          if (selected && !variantNames.has(variantName)) {
            variants.push({
              name: variantName,
              description: variantDescription,
              moduleNames: [repoFormGroup?.get('moduleName')?.value ?? ""]
            });
            variantNames.add(variantName);
          } else if (selected) {
            let variantIndex = variants.findIndex((variant) => variant.name === variantName);
            if (variantIndex >= 0) {
              variants[variantIndex].moduleNames.push(repoFormGroup?.get('moduleName')?.value ?? "");
            }
          
          }
        }
      }
      this.projectService.projectsCreate({
        name: this.projectCreationForm.value.projectDetailsForm?.name ?? "",
        description: this.projectCreationForm.value.projectDetailsForm?.description ?? "",
        responsibleUsers: [],
        repositories: repositories,
        runnerLabels: runnerLabels,
        variants: variants
      }).subscribe();
    }

    private getVariantById(variantId: string): FormGroup | null {
      return this.variants.controls.find((variant) => (variant as FormGroup).get('id')?.value === variantId) as FormGroup;
    }

    onAddVariantClicked() {
      let variantId = uuidv4();
      let variantName = this.projectCreationForm?.get('newVariantDetailsForm')?.get('name')?.value;
      let description = this.projectCreationForm?.get('newVariantDetailsForm')?.get('description')?.value;
      const variantsArray = this.projectCreationForm?.get('variantsDetailsForm')?.get('variants') as FormArray;
      const newVariant = new FormGroup({
        id: new FormControl(variantId),
        name: new FormControl(variantName),
        description: new FormControl(description),
        modules: new FormArray([])
      });
      variantsArray.push(newVariant);

      for (let repo of this.repositories.controls) {
        (repo.get('variants') as FormArray).push(new FormGroup({
          variantId: new FormControl(variantId),
          name: new FormControl(variantName),
          selected: new FormControl(false)
        }));
      }
      this.isAddNewVariantDialogOpen = false;
      this.projectCreationForm?.get('newVariantDetailsForm')?.get('name')?.setValue('');
      this.projectCreationForm?.get('newVariantDetailsForm')?.get('description')?.setValue('');
    }

    onDeleteVariantClicked() {
      if (this.selectedVariantId) {
        let variantIndex = this.variants.controls.findIndex((variant) => (variant as FormGroup).get('id')?.value === this.selectedVariantId);
        this.variants.removeAt(variantIndex);
        for (let repo of this.repositories.controls) {
          let variantIndex = (repo.get('variants') as FormArray).controls.findIndex((variant) => (variant as FormGroup).get('variantId')?.value === this.selectedVariantId);
          (repo.get('variants') as FormArray).removeAt(variantIndex);
        }
      }
    }

    onEditVariantClicked() {
      if (this.selectedVariantId) {
        let variantIndex = this.variants.controls.findIndex((variant) => (variant as FormGroup).get('id')?.value === this.selectedVariantId);
        let variant = this.variants.controls[variantIndex] as FormGroup;
        this.projectCreationForm?.get('newVariantDetailsForm')?.get('name')?.setValue(variant.get('name')?.value);
        this.projectCreationForm?.get('newVariantDetailsForm')?.get('description')?.setValue(variant.get('description')?.value);
        this.isAddNewVariantDialogOpen = true;
      }
    }

    onVariantSelectedClicked(variantId: string) {
      // let allVariants = this.variants.controls as FormGroup[];
      // let selectedVariants = allVariants.filter((variant) => variant.get('selected')?.value === true);
      // this.areRequiredOptionsSelected = selectedVariants.length > 0;
      // alert(variantId);
      this.selectedVariantId = variantId;
    }

    selectedVariantId: string = '';
  }