<app-page-header title="Projects">
    <fluent-button (click)="navigateToNewProject()" appearance="accent">Create Project</fluent-button>
</app-page-header>

<div class="page-content">
    <fluent-tabs activeId="all">
        <fluent-tab id="all">All</fluent-tab>
        <fluent-tab id="my">Mine</fluent-tab>

        <fluent-tab-panel id="all">
            <table class="detail-table">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Description</th>
                        <th>Creation date</th>
                        <th>Created by</th>
                    </tr>
                </thead>
                <tbody>
                    @for (project of projects$ | async; track $index) {
                        <tr>
                            <td><a [routerLink]="[project.id]">{{project.name}}</a></td>
                            <td>{{project.description}}</td>
                            <td>{{project.creationDate | date}}</td>
                            <td>{{project.createdByName}}</td>
                        </tr>
                    }
                </tbody>
            </table>
        </fluent-tab-panel>
        <fluent-tab-panel id="my"></fluent-tab-panel>
    </fluent-tabs>
</div>