import { Injectable } from "@angular/core";
import { BaseResourceService } from "./base-resource.service";
import { Observable, map } from "rxjs";
import { JobCreationModel } from "../models/job-creation-model";
import AvlJob from "../models/job";
import AvlJobDetail from "../models/job-detail";

@Injectable({
    providedIn: 'root'
})
export class JobService extends BaseResourceService {
    private readonly _resourceEndpoint: string = 'api/jobs';

    jobsCreate<T>(job: JobCreationModel): Observable<T> {
      return this.post<T, JobCreationModel>(`${this._resourceEndpoint}/create`, job);
    }

    jobsGetAll(userFilter: string | 'all' | 'my', fromDate?: string, toDate?: string, pageSize: number = 100, pageSkip: number = 0, orderBy: string | 'name'| 'name_desc' | 'creationdate' | 'creationdate_desc' | 'executiondate' | 'executiondate_desc' = "creationdate"): Observable<AvlJob[]> {
      return this.get<{jobs: AvlJob[]}>(`${this._resourceEndpoint}/getall?userFilter=${userFilter}&pageSize=${pageSize}&pageSkip=${pageSkip}`)
        .pipe(map(response => response.jobs));
    }

    jobsExecuteBuild<T>(jobId: string): Observable<T> {
      return this.get(`${this._resourceEndpoint}/execute/build?jobId=${jobId}`);
    }

    jobsGetById(jobId: string): Observable<AvlJobDetail> {
      return this.get<AvlJobDetail>(`${this._resourceEndpoint}/getbyid?jobId=${jobId}`);
    }

    jobsDownloadReport(jobId: string): Observable<Blob> {
      return this.get<Blob>(`${this._resourceEndpoint}/download/report?jobId=${jobId}`, { responseType: 'blob'});
    }
}