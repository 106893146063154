<div class="nav-header">
    <div class="nav-left">    
        <!-- App title -->
        <span class="app-title">AVL DevOps Pilot</span>
    </div>
    
    <div class="nav-right">
        <div class="nav-menu">
            <button class="nav-menu-btn" type="button">
                <i class="ms-Icon ms-Icon--Add"></i>
            </button>
            <button class="nav-menu-btn" type="button">
                <i class="ms-Icon ms-Icon--Ringer"></i>
            </button>
            <button class="nav-menu-btn" type="button">
                <i class="ms-Icon ms-Icon--Settings"></i>
            </button>
            <button class="nav-menu-btn" type="button">
                <i class="ms-Icon ms-Icon--Help"></i>
            </button>
    
            <!-- Avatar -->
            <div class="nav-avatar">
                <a class="dropdown-toggle" data-toggle="dropdown">
                    <!-- <img (click)="btnUserMenuClick($event)" src="/assets/avatar-placeholder.jpg" alt="avatar"> -->
                    <img (click)="btnUserMenuClick($event)" [src]="userImageUrl" alt="avatar">
                </a>
            </div>
        </div>
        
        @if(showUserMenu) {
            <fluent-menu style="position: absolute; right: 5px; top: 45px;">
                <fluent-menu-item (click)="btnLogoutClick($event)">Logout</fluent-menu-item>
            </fluent-menu>
        }
        
    </div>
</div>