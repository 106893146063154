import { CUSTOM_ELEMENTS_SCHEMA, Component, OnInit } from '@angular/core';
import { Observable, interval, map, startWith, switchMap, take } from 'rxjs';
import { JobService } from '../../services/job.service';
import AvlJob from '../../models/job';
import { CommonModule } from '@angular/common';
import { PageHeaderComponent } from '../../components/page-header/page-header.component';
import { Router, RouterModule } from '@angular/router';
import Utils from '../../utils/utils';
import { CapitalizeFirstLetterPipe } from '../../pipes/capitalize-first-letter.pipe';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-job-list-page',
  standalone: true,
  imports: [
    RouterModule,
    CommonModule,
    PageHeaderComponent,
    CapitalizeFirstLetterPipe
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  templateUrl: './job-list-page.component.html',
  styleUrl: './job-list-page.component.scss'
})
export class JobListPageComponent implements OnInit {

  constructor(
    private jobService: JobService,
    private router: Router) { }
    
  jobs$!: Observable<AvlJob[]>;
  
  navigateToNewJob() {
    this.router.navigate(['/jobs/new']);
  }

  ngOnInit() {
    this.jobs$ = interval(3000).pipe(
      startWith(0), // Emit value immediately
      switchMap(() => this.jobService.jobsGetAll('all')),
      map(data => Utils.getRecentJob(data))
    );
  }

  onClickStartExecution(jobID: string) {
    this.jobService.jobsExecuteBuild(jobID).pipe(
      take(1)
    ).subscribe();
  }

  downloadReport(jobID: string) {
    this.jobService.jobsDownloadReport(jobID).subscribe((results: Blob) => {
      const blob = new Blob([results], { type: 'application/zip' });
      saveAs(blob, 'report.zip');
    });
  }
}
