<app-page-header title="Jobs">
    <fluent-button (click)="navigateToNewJob()" appearance="accent">Create Job</fluent-button>
</app-page-header>

<div class="page-content">
    <fluent-tabs activeId="all">
        <fluent-tab id="all">All</fluent-tab>
        <fluent-tab id="my">Mine</fluent-tab>

        <fluent-tab-panel id="all">
            <table class="detail-table" id="jobs-detail">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Status</th>
                        <th>Project</th>
                        <th>Variant</th>
                        <th>Creation date</th>
                        <th>Created by</th>
                        <th>Execution date</th>
                        <th id="jobs-detail-icon">Issue</th>
                        <th id="jobs-detail-icon">Report</th>
                        <th id="jobs-detail-icon">Execute</th>
                    </tr>
                </thead>
                <tbody>
                    @for (job of jobs$ | async; track $index) {
                        <tr>
                            <td><a [routerLink]="[job.jobId]">{{job.jobName}}</a></td>
                            @if((job.jobStatus | lowercase) == "in_progress") {
                                <td><span class="pill {{job.jobStatus | lowercase}}">Running</span></td>
                            }
                            @else {
                                <td><span class="pill {{job.jobStatus | lowercase}}">{{job.jobStatus | capitalizeFirstLetter}}</span></td>
                            }
                            <td><a [routerLink]="['/projects/' + job.projectId]">{{job.projectName}}</a></td>
                            <td>{{job.projectVariantName}}</td>
                            <td>{{job.jobCreationDate | date}}</td>
                            <td>{{job.jobCreatedByName}}</td>
                            <td>{{job.jobExecutionDate | date}}</td>

                            @if(job.jobUrl) {
                                <td id="jobs-detail-icon"><a href="{{job.jobUrl}}" class="icon"><i class="ms-Icon ms-Icon--OpenInNewTab" id="icon-issue"></i></a></td>
                            }
                            @else {
                                <td id="jobs-detail-icon"><a style="opacity: 0;" class="icon"><i class="ms-Icon ms-Icon--OpenInNewTab" id="icon-issue"></i></a></td>
                            }
                            @if((job.jobStatus | lowercase) == 'completed') {
                                <td id="jobs-detail-icon"><a (click)="downloadReport(job.jobId)" class="icon-execute"><i class="ms-Icon ms-Icon--TextDocument" id="icon-report"></i></a></td>
                            }
                            @else {
                                <td id="jobs-detail-icon"><a style="opacity: 0;" class="icon"><i class="ms-Icon ms-Icon--TextDocument" id="icon-report"></i></a></td>
                            }
                            @if ((job.jobStatus | lowercase) == 'queued' || (job.jobStatus | lowercase) == 'in_progress') {
                                <td></td>
                            }
                            @else {
                                <td id="jobs-detail-icon"><a (click)="onClickStartExecution(job.jobId)" class="icon-execute"><i class="ms-Icon ms-Icon--Play" id="icon-execute"></i></a></td>
                            }
                        </tr>
                    }
                </tbody>
            </table>
        </fluent-tab-panel>
        <fluent-tab-panel id="my"></fluent-tab-panel>
    </fluent-tabs>
</div>