@if (loading) {
    <div class="object-wrapper">
        <div class="object-div">
            <div class="object">
                <fluent-progress-ring></fluent-progress-ring>
            </div>
            <br />
            Loading, please wait...
        </div>
    </div>
} 