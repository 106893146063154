<app-page-loader></app-page-loader>
<div class="app-container">
  <app-nav-bar class="nav-bar"></app-nav-bar>
  <div class="content-container">
    <app-side-nav class="side-nav"></app-side-nav>
    <div class="main-content">
      <ng-container *ngIf="notification$ | async as notification">
        <div *ngIf="notification.messageText">
          <div class="notification-container" 
            [class.error]="notification.type === 0"
            [class.warning]="notification.type === 1"
            [class.info]="notification.type === 2"
            [class.ok]="notification.type === 3">
            <div class="content">
              {{ notification.messageText }}
            </div>
          </div>
        </div>
      </ng-container>
      <router-outlet></router-outlet>
    </div>
  </div>
</div>