import { Injectable } from "@angular/core";
import { BaseResourceService } from "./base-resource.service";
import { Observable, interval, startWith, switchMap, tap } from "rxjs";
import { AvlDashboardJobsModel, AvlDashboardMetaModel } from "../models/dashboard";
import { AvlNotification, AvlNotificationRead } from "../models/notification-model";
import { HttpClient } from "@angular/common/http";

@Injectable({
    providedIn: 'root'
})
export class NotificationService extends BaseResourceService {
    private readonly _resourceEndpoint: string = 'api/notifications';
    private notification$: Observable<AvlNotification>;

    constructor(private http: HttpClient) {
        super(http);
        this.notification$ = interval(3000).pipe(
            startWith(0),
            switchMap(() => this.get<AvlNotification>(`${this._resourceEndpoint}/getnext`)),
            tap(notification => {
                if (notification) {
                    let id = notification.id;
                    setTimeout(() => {
                        this.notificationMarkAsRead(id).subscribe();
                    }, 8000);
                }
            })
        );
    }

    public notificationGetNext(): Observable<AvlNotification> {
        return this.notification$;
    }

    public notificationMarkAsRead<T>(notificationId: string): Observable<any> {
        if (!notificationId) {
            return new Observable<any>();
        }
        let notificationRead: AvlNotificationRead = { 
            ids: [notificationId],
            read: true
        };
        return this.post<T, AvlNotificationRead>(`${this._resourceEndpoint}/markread?notificationId=${notificationId}`, notificationRead);
    }
}