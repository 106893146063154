import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';

@Injectable({
    providedIn: 'root'
})
export abstract class BaseResourceService {
    constructor(protected _httpClient: HttpClient) { }

    protected get<TResult>(resourceEndpoint: string, options?: {[key: string]: string | number}): Observable<TResult> {
        let apiURL: string = `${environment.baseAPIUrl}/${resourceEndpoint}`;
        return this._httpClient.get<TResult>(apiURL, options);
    }

    protected post<TResult, TBody>(resourceEndpoint: string, body: TBody): Observable<TResult> {
        let apiURL: string = `${environment.baseAPIUrl}/${resourceEndpoint}`;
        return this._httpClient.post<TResult>(apiURL, body);
    }
}
