<div class="side-nav">
    <ul class="side-nav-menu">
        <li class="menu-item">
            <a routerLink="" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                <i class="ms-Icon ms-Icon--Home"></i>
                <span>Home</span>
            </a>
        </li>
        <li class="divider"></li>
        <li class="menu-item">
            <a routerLink="/projects" routerLinkActive="active">
                <span class="marker"></span>
                <i class="ms-Icon ms-Icon--ProjectCollection"></i>
                <span>Projects</span>
            </a>
        </li>
        <li class="menu-item">
            <a routerLink="/jobs" routerLinkActive="active">
                <i class="ms-Icon ms-Icon--TextDocument"></i>
                <span>Jobs</span>
            </a>
        </li>
        <li class="divider"></li>
        <li class="menu-item">
            <a href="">
                <i class="ms-Icon ms-Icon--LearningTools"></i>
                <span>Learn</span>
            </a>
        </li>
    </ul>
</div>