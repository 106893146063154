import { CUSTOM_ELEMENTS_SCHEMA, Component } from '@angular/core';
import { PageHeaderComponent } from '../../components/page-header/page-header.component';
import { ProjectService } from '../../services/project.service';
import { CommonModule } from '@angular/common';
import { Router, RouterModule } from '@angular/router';
import { Observable, map } from 'rxjs';
import AvlProject from '../../models/project';
import Utils from '../../utils/utils';

@Component({
  selector: 'app-project-list-page',
  standalone: true,
  imports: [
    RouterModule,
    CommonModule,
    PageHeaderComponent
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  templateUrl: './project-list-page.component.html',
  styleUrl: './project-list-page.component.scss'
})
export class ProjectListPageComponent {
  projects$: Observable<AvlProject[]> = this.projectService.projectsGetAll('all')
  .pipe(
    map(data => Utils.getRecentProject(data)),
  );

  constructor(
    private projectService: ProjectService,
    private router: Router
    ) {}

  navigateToNewProject() {
    this.router.navigate(['/projects/new']);
  }
}

