import { APP_INITIALIZER, ApplicationConfig } from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { environment } from './environments/environment';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptors, withInterceptorsFromDi } from '@angular/common/http';
import { KeycloakAngularModule, KeycloakBearerInterceptor, KeycloakService } from 'keycloak-angular';
import { MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalBroadcastService, MsalGuard, MsalGuardConfiguration, MsalInterceptor, MsalInterceptorConfiguration, MsalModule, MsalService } from '@azure/msal-angular';
import { BrowserCacheLocation, IPublicClientApplication, InteractionType, LogLevel, PublicClientApplication } from '@azure/msal-browser';

function initializeKeycloak(keycloak: KeycloakService) {
  return () =>
    keycloak.init({
      config: {
        url: environment.keycloakUrl,
        realm: environment.keycloakRealm,
        clientId: environment.keycloakClientId,
      },
      initOptions: {
        checkLoginIframe: false,
      },
      loadUserProfileAtStartUp: true
    });
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.azureAdClientId, 
      authority: environment.azureAdAuthority, 
      redirectUri: environment.baseAPIUrl, 
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false,
      },
    },
  });
}

export function loggerCallback(logLevel: LogLevel, message: string) {
  //  console.log(message);
}

const isIE =
window.navigator.userAgent.indexOf("MSIE ") > -1 ||
window.navigator.userAgent.indexOf("Trident/") > -1;

/*
  Microsoft Graph API endpoint. Microsoft Graph is a RESTful web API that enables you to access Microsoft Cloud service resources.
  By including GRAPH_ENDPOINT in the protectedResourceMap, we're telling MSAL that when the application makes a request to 'https://graph.microsoft.com/v1.0/me', 
  it should include an access token with the user.read scope in the request. The user.read scope allows the application to sign in the user and read the user's profile.
 */
const GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0/me';
const GRAPH_PHOTO_ENDPOINT = 'https://graph.microsoft.com/v1.0/me/photo/$value';

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set(`${environment.baseAPIUrl}/api`, [`api://${environment.azureAdClientId}/${environment.azureAdBaseApiScope}`]);
  protectedResourceMap.set(GRAPH_ENDPOINT, ['user.read']);  // to get access to data about currently authenticated user from graph api
  protectedResourceMap.set(GRAPH_PHOTO_ENDPOINT, ['user.read', 'user.readbasic.all']);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: ['user.read', 'openid', environment.azureAdBaseApiScope],
    },
  };
}

/*
  The appConfig object is used to provide the necessary configuration for the application. 
  This config allows to swap out auth providers. It includes the providers array, which is used to provide the necessary services to the application. 
  The appConfig object is passed to the AppModule as an argument when the application is bootstrapped.
*/
export const appConfig: ApplicationConfig = {
  providers: environment.azureAdClientId
    ? [
      provideHttpClient(
        withInterceptorsFromDi()
      ),
      {
        provide: HTTP_INTERCEPTORS,
        useClass: MsalInterceptor,
        multi: true,
      },
      {
        provide: MSAL_INSTANCE,
        useFactory: MSALInstanceFactory,
      },
      {
        provide: MSAL_GUARD_CONFIG,
        useFactory: MSALGuardConfigFactory,
      },
      {
        provide: MSAL_INTERCEPTOR_CONFIG,
        useFactory: MSALInterceptorConfigFactory,
      },
      MsalService,
      MsalGuard,
      MsalBroadcastService,
      provideRouter(routes)
    ]
    : [
      provideHttpClient(
        withInterceptorsFromDi()
      ),
      KeycloakService,
      KeycloakAngularModule,
      {
        provide: APP_INITIALIZER,
        useFactory: initializeKeycloak,
        multi: true,
        deps: [KeycloakService]
      },
      provideRouter(routes),
      {
        provide: HTTP_INTERCEPTORS,
        useClass: KeycloakBearerInterceptor,
        multi: true,
        deps: [KeycloakService]
      },
    ]
};
