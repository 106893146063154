import { CUSTOM_ELEMENTS_SCHEMA, Component } from '@angular/core';

import { CommonModule } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { NavBarComponent } from './components/nav-bar/nav-bar.component';
import { SideNavComponent } from './components/side-nav/side-nav.component';
import { NotificationService } from './services/notification.service';
import { PageLoaderComponent } from './components/page-loader/page-loader.component';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [ 
    CommonModule, RouterOutlet, FormsModule,
    NavBarComponent, SideNavComponent, PageLoaderComponent
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class AppComponent {
  title = 'AVL DevOps Pilot';
  exampleTextField = '';

  constructor(private notificationService: NotificationService) { }

  get notification$() {
    return this.notificationService.notificationGetNext();
  }

  dismissNotification(id: string) {
    this.notificationService.notificationMarkAsRead(id).subscribe();
  }
}
