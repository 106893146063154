<div class="container">
    <fluent-breadcrumb>
        @for (item of breadcrumbs; track $index) {
            @if(item.relativePathFromRoot) {
                <fluent-breadcrumb-item><a [routerLink]="[item.relativePathFromRoot]">{{item.item_name}}</a></fluent-breadcrumb-item>
            }
            @else {
                <fluent-breadcrumb-item>{{item.item_name}}</fluent-breadcrumb-item>
            }
        }
    </fluent-breadcrumb>
</div>