import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

import Chart, { ChartTypeRegistry } from 'chart.js/auto';

@Component({
  selector: 'app-chart',
  standalone: true,
  imports: [],
  templateUrl: './chart.component.html',
  styleUrl: './chart.component.scss'
})
export class ChartComponent implements OnInit, OnChanges {
  chart: any;
  config: any;

  chartId = 'chart-canvas_' + Math.random().toString(36);

  @Input() type: string = 'bar';
  @Input() data: {
    labels: string[];
    datasets: {
        label: string;
        backgroundColor: string;
        data: number[];
        stack: string;
    }[]
  } = {
    labels: [],
    datasets: []
  }

  constructor(private cd: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.chart = new Chart(this.chartId, {
      type: this.type as keyof ChartTypeRegistry,
      data: this.data,
      options: {
        scales: {
          y: {
            stacked: true,
          },
          x: {
            stacked: true,
          },
        },
        responsive: true,
        maintainAspectRatio: false
      },
    });
    this.cd.detectChanges();
  }

  ngOnInit(): void {
  }
}
