// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  baseAPIUrl: (window as any)['env']['baseApiUrl'],
  keycloakUrl: (window as any)['env']['keycloakUrl'],
  keycloakRealm: (window as any)['env']['keycloakRealm'],
  keycloakClientId: (window as any)['env']['keycloakClientId'],
  azureAdClientId: (window as any)['env']['azureAdClientId'],
  azureAdAuthority: (window as any)['env']['azureAdAuthority'],
  azureAdBaseApiScope: (window as any)['env']['azureAdBaseApiScope']
};
