import { Injectable } from "@angular/core";
import { BaseResourceService } from "./base-resource.service";
import { Observable, map } from "rxjs";
import { ProjectCreationModel } from "../models/project-creation-model";
import AvlProject from "../models/project";
import AvlProjectVariant, { AvlProjectVariantModule } from "../models/project-variant";
import AvlProjectDetail from "../models/project-detail";
import AvlAllRunnerLabels, { AvlLabel, AvlRunnerLabelCategory } from "../models/runner-label";
import AvlAllRunnerLabelCategories from "../models/runner-label";

@Injectable({
    providedIn: 'root'
})
export class ProjectService extends BaseResourceService {
    private readonly _resourceEndpoint: string = 'api/projects';

    public projectsCreate<T>(project: ProjectCreationModel): Observable<T> {
        return this.post<T, ProjectCreationModel>(`${this._resourceEndpoint}/create`, project);
    }

    public projectsGetAll(
        userFilter: string | 'all' | 'my',
        fromDate?: string | null,
        toDate?: string | null,
        pageSize: number = 100,
        pageSkip: number = 0): Observable<AvlProject[]> {
        return this.get<{projects: AvlProject[]}>(
            `${this._resourceEndpoint}/getall?userFilter=${userFilter}&pageSize=${pageSize}&pageSkip=${pageSkip}`)
            .pipe(map(response => response.projects));
    }

    public projectsGetById(projectId: string): Observable<AvlProjectDetail> {
        return this.get<AvlProjectDetail>(`${this._resourceEndpoint}/getbyid?projectId=${projectId}`);
    }

    public projectVariantsGetByProjectId(projectId: string): Observable<AvlProjectVariant[]> {
        return this.get<{projectVariants: AvlProjectVariant[]}>(
            `${this._resourceEndpoint}/projectvariant/getbyprojectid?projectId=${projectId}`)
            .pipe(map(response => response.projectVariants));
    }

    public projectsProjectvariantUpdaterepos<T>(projectVariantId: string): Observable<T> {
        return this.get<T>(`${this._resourceEndpoint}/projectvariant/updaterepos?projectVariantId=${projectVariantId}`);
    }

    public projectsProjectvariantModulesGetByProjectVariantId<T>(projectVariantId: string): Observable<AvlProjectVariantModule[]> {
        return this.get<{modules: AvlProjectVariantModule[]}>(
            `${this._resourceEndpoint}/projectvariant/modules/getbyprojectvariantid?projectVariantId=${projectVariantId}`)
            .pipe(map(response => response.modules));
    }

    public projectsRunnerlabelsGetAll(excludeCategories: string[]): Observable<AvlRunnerLabelCategory[]> {
        return this.get<AvlAllRunnerLabelCategories>(`${this._resourceEndpoint}/runnerlabels/getall`)
        .pipe(
            map(response => response.runnerLabelCategories),
            map(categories => categories.filter(category => !excludeCategories.map(c => c.toLowerCase()).includes(category.name.toLowerCase())))
        );
    }

    public projectsRunnerLabelsGetOnly(includeCategories: string[]): Observable<AvlRunnerLabelCategory[]> {
        return this.get<AvlAllRunnerLabelCategories>(`${this._resourceEndpoint}/runnerlabels/getall`)
        .pipe(
            map(response => response.runnerLabelCategories),
            map(categories => categories.filter(category => includeCategories.map(c => c.toLowerCase()).includes(category.name.toLowerCase())))
        );
    }
}