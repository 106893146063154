import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'splitWord',
  standalone: true
})
export class SplitWordPipe implements PipeTransform {
  transform(word: string): string {
    return word.replace(/([A-Z])/g, ' $1').trim();
  }
}