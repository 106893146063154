import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, Component, Input } from '@angular/core';
import { Router, RouterModule } from '@angular/router';

@Component({
  selector: 'app-breadcrumbs-bar',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  templateUrl: './breadcrumbs-bar.component.html',
  styleUrl: './breadcrumbs-bar.component.scss'
})
export class BreadcrumbsBarComponent {
  @Input() breadcrumbs: BreadcrumbElement[] = [];

  constructor(private router: Router) {}
}

export interface BreadcrumbElement {
  item_name: string
  relativePathFromRoot: string | null
}