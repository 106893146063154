import { bootstrapApplication } from '@angular/platform-browser';
import { appConfig } from './app/app.config';
import { AppComponent } from './app/app.component';

import {
  fluentAccordion, fluentAccordionItem,
  fluentBreadcrumb, fluentBreadcrumbItem, fluentButton, fluentCard, 
  fluentCheckbox, 
  fluentCombobox, 
  fluentDataGrid, fluentDataGridCell, fluentDataGridRow,
  fluentDialog,
  fluentListbox,
  fluentMenu,
  fluentMenuItem,
  fluentOption,
  fluentProgressRing,
  fluentRadio,
  fluentRadioGroup,
  fluentSearch,
  fluentTab,
  fluentTabPanel,
  fluentTabs,
  fluentTextArea,
  fluentTextField,
  provideFluentDesignSystem
} from '@fluentui/web-components';


bootstrapApplication(AppComponent, appConfig)
  .catch((err) => console.error(err));

provideFluentDesignSystem().register(
  fluentCard(),
  fluentButton(),
  fluentTextField(),
  fluentTextArea(),

  fluentCombobox(),
  fluentOption(),
  
  // Data Grid
  fluentDataGrid(),
  fluentDataGridCell(),
  fluentDataGridRow(),

  // Bread Crumbs
  fluentBreadcrumb(),
  fluentBreadcrumbItem(),

  // Accordion
  fluentAccordion(),
  fluentAccordionItem(),

  // Radio buttons
  fluentRadio(),
  fluentRadioGroup(),

  // Tabs
  fluentTab(),
  fluentTabPanel(),
  fluentTabs(),

  // List
  fluentListbox(),
  fluentOption(),

  // Search
  fluentSearch(),

  // Checkbox
  fluentCheckbox(),

  // Dialog
  fluentDialog(),

  // Progress
  fluentProgressRing(),

  // Menu
  fluentMenu(),
  fluentMenuItem()
);
