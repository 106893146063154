import { AfterViewInit, CUSTOM_ELEMENTS_SCHEMA, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DataGrid } from '@fluentui/web-components';
import { ChartComponent } from '../../components/chart/chart.component';
import { DashboardService } from '../../services/dashboard.service';
import { AdminService } from '../../services/admin.service';
import { CommonModule } from '@angular/common';
import Chart from 'chart.js/auto';
import { Observable, interval, map, of, startWith, switchMap} from 'rxjs';
import { AvlDashboardJobsModel } from '../../models/dashboard';
import { JobService } from '../../services/job.service';
import AvlJob from '../../models/job';
import Utils from '../../utils/utils';
import { Router, RouterModule } from '@angular/router';

@Component({
  selector: 'app-dashboard-page',
  standalone: true,
  imports: [
    CommonModule,
    ChartComponent,
    RouterModule
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  templateUrl: './dashboard-page.component.html',
  styleUrl: './dashboard-page.component.scss'
})
export class DashboardPageComponent {
  @ViewChild('recentJobsDataGrid') recentJobsDataGrid!: ElementRef<DataGrid>;

  meta$ = this.dashboardService.dashboardMeta();

  jobStatusChart$ = this.dashboardService.dashboardJobs()
    .pipe(switchMap(data => of(this.createJobStatusChart(data))));

  recentJobs$ = interval(3000).pipe(
    startWith(0), // Emit value immediately
    switchMap(() => this.jobService.jobsGetAll('all', undefined, undefined, 8, 0, 'execution_date')),
    map(data => Utils.getRecentJob(data).slice(0, 8))
  );

  recentJobDataRows$ = this.recentJobs$
  .pipe(
    map(data => this.createRecentJobDataRows(data))
  );

  mostRecentJob$: Observable<AvlJob> = this.recentJobs$
  .pipe(
    map(data => data[0])
  );

  mostRecentJobCreationTimeDiff$: Observable<string> = this.mostRecentJob$
  .pipe(
    map(data => Utils.getCreationTimeDiff(Utils.stringToDate(data?.jobCreationDate))
  ));

  latestJobExecutions$: Observable<any> = this.dashboardService.dashboardLatestJobExecutions(30)
  .pipe(
    map(data => Utils.transformAvlDashboardJobExecutionsModel(data))
  );

  constructor(
    private dashboardService: DashboardService,
    private adminService: AdminService,
    private router: Router,
    private jobService: JobService) 
    { 
      this.adminService.upsertUser().subscribe(result => {
        console.log("=========== User ===========");
        console.log(result);
        console.log("============================");
      }); 
    }

  createJobStatusChart(data: AvlDashboardJobsModel) {
    const map = {
      completed: { label: 'Completed', data: data.completed, color: '#2c9d21' },
      running: { label: 'Running', data: data.running, color: '#289ecf' },
      queued: { label: 'Queued', data: data.queued, color: '#7f7f7f' },
      failed: { label: 'Failed', data: data.failed, color: '#f0605c' },
      cancelled: { label: 'Cancelled', data: data.cancelled, color: '#b58726' },
      ready: { label: 'Ready', data: data.ready, color: '#8f74be' }
    }

    new Chart('jobStatusChart', {
      type: 'doughnut',
      data: {
        labels: [
          map.completed.label,
          map.running.label,
          map.queued.label,
          map.failed.label,
          map.cancelled.label,
          map.ready.label
        ],
        datasets: [{
          label: 'Job Status',
          data: [
            map.completed.data,
            map.running.data,
            map.queued.data,
            map.failed.data,
            map.cancelled.data,
            map.ready.data
          ],
          backgroundColor: [
            map.completed.color,
            map.running.color,
            map.queued.color,
            map.failed.color,
            map.cancelled.color,
            map.ready.color
          ],
          hoverOffset: 4
        }]
      }
    })
  }

  createRecentJobDataRows(data: AvlJob[]) {
    return data.map((job, index: number) => {
      return {
        'Name': job.jobName,
        'JobId': job.jobId,
        'Status': Utils.capitalizeFirstLetter(job.jobStatus),
        'Project': job.projectName,
        'ProjectId': job.projectId,
        'Variant': job.projectVariantName,
        'Agent': job.runnerName,
      }})
  }

  onClickViewAllJobs() {
    this.router.navigate(['/jobs']);
  }

  onClickNewJob() {
    this.router.navigate(['/jobs/new']);
  }
}
