<ng-container *ngIf="project$ | async as project">
    <app-breadcrumbs-bar *ngIf="breadcrumbs" [breadcrumbs]="breadcrumbs"></app-breadcrumbs-bar>
    <div class="detail-header">
        <div class="detail-primary">
            <div class="detail-column">
                <div class="detail-item">
                    <h1 class="title">{{project.name}}</h1>
                    <p class="subtitle">{{project.description}}</p>
                </div>
            </div>
        </div>

        <div class="detail-secondary">
            <div class="detail-column" id="create-new-job">
                <div class="detail-item">
                    <fluent-button class="create-new-job" appearance="accent" (click)="onClickCreateNewJob(project.id)" ><div class="create-new-job"><i class="ms-Icon ms-Icon--Add"></i><span>Create new job</span></div></fluent-button>
                </div>
            </div>
            <div class="detail-column">
                <div class="detail-item">
                    <span class="title">No. of Variants</span>
                    <span>{{project.variants.length}}</span>
                </div>
                <div class="detail-item">
                    <span class="title">No. of Jobs</span>
                    @if (numJobs$ | async; as numJobs) {
                        <span>{{numJobs}}</span>
                    }
                    @else {
                        <span>0</span>
                    }
                </div>
            </div>
            <div class="detail-column">
                <div class="detail-item">
                    <span class="title">Created on</span>
                    <span>{{project.creationDate | date}}</span>
                </div>
                <div class="detail-item">
                    <span class="title">Created by</span>
                    <span>{{project.createdByName}}</span>
                </div>
            </div>
        </div>
    </div>

    <div class="details-container">
        <fluent-tabs [activeid]="(fluentTabActiveID$ | async)">
            <fluent-tab id="artifacts">Artifacts</fluent-tab>
            <fluent-tab id="agents">Software & Agents</fluent-tab>
            <fluent-tab id="variants">Variants</fluent-tab>
            <fluent-tab-panel id="artifacts">
                <fluent-accordion>
                    <fluent-accordion-item *ngFor="let moduleTypeToRepo of moduleTypeToRepositoriesMap$ | async | keyvalue">
                        <span slot="heading">{{moduleTypeToRepo.key | splitWord}} ({{moduleTypeToRepo.value.length}})</span>
                        <div class="panel">
                            <div class="row" *ngFor="let repo of moduleTypeToRepo.value">
                                <app-panel-text-field class="row-item" textFieldHeader="Name" [textFieldValue]="repo.name"></app-panel-text-field>
                                <app-panel-text-field class="row-item" textFieldHeader="Repository URL" [textFieldValue]="repo.url"></app-panel-text-field>
                                <app-panel-text-field class="row-item" textFieldHeader="Access token" textFieldValue="some_token"></app-panel-text-field>
                            </div>
                        </div>
                    </fluent-accordion-item>
                </fluent-accordion>
            </fluent-tab-panel>

            <fluent-tab-panel id="agents">
                <table id="agents-table">
                    <thead>
                        <tr>
                            <th></th>
                            <th>Tool</th>
                            <th>Version</th>
                        </tr>
                    </thead>
                    <tbody *ngFor="let software of projectSoftwareAndAgentMap | keyvalue; index as i">
                        <tr>
                          <td>{{software.key}}</td>
                          <td>{{software.value}}</td>
                          @if (software.value != 'None') {
                              <td>Latest version</td>
                          } @else {
                            <td></td>
                          }
                        </tr>
                      </tbody>
                </table>
            </fluent-tab-panel>
            <fluent-tab-panel id="variants">
                <div id="variants-content">
                    <div class="list">
                        <h2>Variants ({{project.variants.length}})</h2>
                        <ul>
                            @for (variant of project.variants; track $index)
                            {
                                <li>
                                    <div class="variants-tab">
                                        <div>{{variant.projectVariantName}}</div>
                                        <div>{{variant.projectVariantDescription}}</div>
                                    </div>
                                </li>
                            }
                        </ul>
                    </div>
                    <div class="detail">
                        <h2>Variant Configuration</h2>
                        <table class="detail-table">
                            <thead>
                                <tr>
                                    <th class="project-detail-headers">Model</th>
                                    @for (variant of project.variants; track $index) {
                                        <th class="project-detail-headers">{{variant.projectVariantName}}</th>
                                    }
                                </tr>
                            </thead>

                            <tbody>
                                @for (repo of project.repositories; track $index) {
                                    <tr>
                                        <td>{{repo.name}}</td>
                                        @for(projectVariant of project.variants; track $index){
                                            <td class="project-detail-checkbox">
                                                <i class="ms-Icon ms-Icon--CheckboxCompositeReversed" *ngIf="doesProjectVariantContainsModule(projectVariant.projectVariantId, repo.id, project.variants)"></i>
                                            </td>
                                        }
                                    </tr>
                                }
                            </tbody>
                        </table>



                    </div>
                </div>
            </fluent-tab-panel>
        </fluent-tabs>
    </div>
</ng-container>