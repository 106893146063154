import { CUSTOM_ELEMENTS_SCHEMA, Component, OnInit } from '@angular/core';
import { BreadcrumbsBarComponent, BreadcrumbElement } from '../../components/breadcrumbs-bar/breadcrumbs-bar.component';
import { PageHeaderComponent } from '../../components/page-header/page-header.component';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { JobService } from '../../services/job.service';
import { ProjectService } from '../../services/project.service';
import { CommonModule } from '@angular/common';
import { Observable, tap, map, take } from 'rxjs';
import AvlProject from '../../models/project';
import AvlProjectVariant, { AvlProjectVariantModule } from '../../models/project-variant';
import { ActivatedRoute, Router } from '@angular/router';
import { AvlModuleVersion } from '../../models/job-detail';
import { SplitWordPipe } from '../../pipes/split-word';
import { AvlLabel } from '../../models/runner-label';
import RegexPatterns from '../../utils/regexpatterns';
import { PageLoaderService } from '../../services/page-loader.service';

enum WizardStep {
  JobFormDetails = 0,
  ModuleVersions = 1,
  MachineDetails = 2
}

@Component({
  selector: 'app-job-creation-page',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    BreadcrumbsBarComponent, PageHeaderComponent,
    SplitWordPipe
  ],
  providers: [
    JobService, ProjectService
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './job-creation-page.component.html',
  styleUrl: './job-creation-page.component.scss'
})
export class JobCreationPageComponent implements OnInit {
  currentWizardStep: WizardStep = WizardStep.JobFormDetails;
  // areRequiredOptionsSelected: boolean = false;
  breadcrumbs: BreadcrumbElement[] = [
    {item_name: 'Jobs', relativePathFromRoot: '/jobs'},
    {item_name: 'New Job', relativePathFromRoot: null}
  ]

  projects$: Observable<AvlProject[]> | undefined;
  selectedProjectId: any;

  variants$: Observable<AvlProjectVariant[]> | undefined;
  modules$: Observable<AvlProjectVariantModule[]> | undefined;
  moduleTypes: Map<string, Set<string>> = new Map();

  fileModuleVersionAssignment: any = [];
  paramFileModuleVersionAssignment: any = [];

  infrastructureRunnerLabels$: Observable<AvlLabel[]> = this.projectService.projectsRunnerLabelsGetOnly(['infrastructure']).pipe(
    map(category => category?.[0]?.runnerLabels ?? [])
  );

  lengthOfModuleVersions: number = 0;
  paramsSelectedModuleName: Set<string> = new Set();
  optionsSelectedModuleName: Set<string> = new Set();;

  constructor(
    private jobService: JobService,
    private projectService: ProjectService,
    private router: Router,
    private activatedRoutes: ActivatedRoute,
    private pageLoaderService: PageLoaderService
  ) {
    this.activatedRoutes.queryParams.pipe(
      take(1),
      map(response => response['projectId'])
    )
    .subscribe(projectId => {
      if (projectId) {
        this.onProjectChanged(projectId);
      }
    })
  }

  setParamsSelected(moduleName: string) {
    this.paramsSelectedModuleName.add(moduleName);
  }

  setOptionsSelected(moduleName: string) {
    this.optionsSelectedModuleName.add(moduleName);
  }

  getTotalSelectionLength() {
    return this.paramsSelectedModuleName.size + this.optionsSelectedModuleName.size;
  }

  ngOnInit(): void {
    this.projects$ = this.projectService.projectsGetAll('all', null, null, 100, 0);
  }

  jobCreationForm = new FormGroup({
    projectDetailsForm: new FormGroup({
      projectId: new FormControl('', [Validators.required]),
      variantId: new FormControl('', [Validators.required]),
    }),
    jobDetailsForm: new FormGroup({
      name: new FormControl('',[Validators.required, Validators.pattern(/\S/)]),
      description: new FormControl(''),
      meta: new FormControl('', [Validators.pattern(RegexPatterns.ValidUrl)]),
    }),
    infrastructureForm: new FormGroup({
      type: new FormControl('', [Validators.required]),
    })
  });

  onProjectChanged(projectId: string) {
    this.selectedProjectId = projectId;
    this.jobCreationForm.patchValue({
      projectDetailsForm: {
        projectId: this.selectedProjectId,
        variantId: '',
      },
    });

    this.variants$ = this.projectService.projectVariantsGetByProjectId(this.selectedProjectId);
  }

  onProjectVariantChanged(variantId: string) {
    this.jobCreationForm.patchValue({
      projectDetailsForm: {
        projectId: this.selectedProjectId,
        variantId: variantId
      },
    });

    this.refreshModules();
  }

  onFileModuleVersionChanged(moduleId: string, moduleVersion: string) {
    this.fileModuleVersionAssignment[moduleId] = moduleVersion;
  }

  onParamFileModuleVersionChanged(moduleId: string, moduleVersion: string) {
    this.paramFileModuleVersionAssignment[moduleId] = moduleVersion;
  }

  onCancelClicked() {
    this.router.navigate(['/jobs']);
  }

  moduleVersionsSort(moduleVersion1: AvlModuleVersion, moduleVersion2: AvlModuleVersion) {
    const versionA = moduleVersion1.version.split('.').map(Number);
    const versionB = moduleVersion2.version.split('.').map(Number);
    for (let i = 0; i < Math.max(versionA.length, versionB.length); i++) {
        const diff = (versionA[i] || 0) - (versionB[i] || 0);

        if (diff !== 0) {
            return diff;
        }
    }

    return 0;
  }

  refreshModules() {
    this.pageLoaderService.show();
    this.projectService.projectsProjectvariantUpdaterepos(this.jobCreationForm.value.projectDetailsForm!.variantId!).subscribe(x => {
      this.modules$ = this.projectService.projectsProjectvariantModulesGetByProjectVariantId(
        this.jobCreationForm.value.projectDetailsForm!.variantId!)
        .pipe(tap(modules => {
          for (let module of modules)
          {
            module.params = [];
            module.options = [];
            let moduleVersions = module.moduleVersions.sort(this.moduleVersionsSort);

            for (let moduleVersion of moduleVersions) {
              if ((module.type === "PlantModel" || module.type === "ControllerModel") &&  moduleVersion.name.toLowerCase().endsWith(".dcm")) {
                module.params.push({label: moduleVersion.name, value: moduleVersion.id});
              } else {
                module.options.push({label: moduleVersion.name, value: moduleVersion.id});
              }

              if (!this.moduleTypes.has(module.type)) {
                this.moduleTypes.set(module.type, new Set());
              }
              this.moduleTypes.get(module.type)!.add(module.id);
            }

          }
        }));

      this.modules$
      .pipe(take(1))
      .subscribe(modules => {
        // Determine the total number of required selections for step 2 (selecting module versions) to
        // check if we should enable the next button
        // for (let module of modules) {
        //   if (module.options && module.options.length > 0) {
        //     this.lengthOfModuleVersions += 1;
        //   }

        //   if (module.params && module.params.length > 0)  {
        //     this.lengthOfModuleVersions += 1;
        //   }
        // }

        for (let module of modules) {
          let jobModuleVersions = [];
          for (let moduleVersion of module.options!) {
            this.onFileModuleVersionChanged(module.id, moduleVersion.value);
          }

          for (let moduleVersion of module.params!) {
            this.onParamFileModuleVersionChanged(module.id, moduleVersion.value);
          }
        }
      });
      this.pageLoaderService.hide();
    });
  }

  handleWizard() {
    console.log('Wizard form state', this.jobCreationForm.value);
    if (this.currentWizardStep < WizardStep.MachineDetails) {
      this.currentWizardStep++;
      return;
    }

    this.submitForm();
  }

  wizardBack() {
    if (this.currentWizardStep > WizardStep.JobFormDetails) {
      this.currentWizardStep--;
    }
  }

  // This function enables the next button by toggling [disabled]="!enableWizard()"" in the html.
  enableWizardNext() {
    switch (this.currentWizardStep) {
      case WizardStep.JobFormDetails:
        return this.jobCreationForm.get("projectDetailsForm")?.valid === true 
          && this.jobCreationForm.get("jobDetailsForm")?.valid === true;
      case WizardStep.ModuleVersions:
        return Object.keys(this.fileModuleVersionAssignment).every(key => !!this.fileModuleVersionAssignment[key])
          && Object.keys(this.paramFileModuleVersionAssignment).every(key => !!this.paramFileModuleVersionAssignment[key]);
      case WizardStep.MachineDetails:
        return this.jobCreationForm.get("infrastructureForm")?.valid === true;
    }
  }

  submitForm() {
    let jobModuleVersions = [];
    for (let entry in this.fileModuleVersionAssignment)
      jobModuleVersions.push(this.fileModuleVersionAssignment[entry]);
    for (let entry in this.paramFileModuleVersionAssignment) {
      jobModuleVersions.push(this.paramFileModuleVersionAssignment[entry]);
    }

    this.jobService.jobsCreate({
      jobName: this.jobCreationForm.value.jobDetailsForm!.name ?? '',
      jobDescription: this.jobCreationForm.value.jobDetailsForm!.description ?? '',
      projectVariantId: this.jobCreationForm.value.projectDetailsForm!.variantId ?? '',
      jobUrl: this.jobCreationForm.value.jobDetailsForm!.meta ?? '',
      jobModuleVersions: jobModuleVersions,
      jobLabels: [this.jobCreationForm!.get("infrastructureForm")!.get("type")!.value ?? ""] // can't be null here!
    }).subscribe(success => {
      console.log('Job created successfully', success);
      this.router.navigate(['/jobs']);
    });
  }
}
