import { CUSTOM_ELEMENTS_SCHEMA, Component } from '@angular/core';
import { BreadcrumbElement, BreadcrumbsBarComponent } from '../../components/breadcrumbs-bar/breadcrumbs-bar.component';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { Observable, take, filter, map, switchMap, interval, startWith, combineLatest, tap } from 'rxjs';
import { CommonModule, JsonPipe } from '@angular/common';
import { JobService } from '../../services/job.service';
import AvlJobDetail, { AvlModuleVersion } from '../../models/job-detail';
import { TrimFileNamePipe } from '../../pipes/trim-file-name.pipe';
import { CapitalizeFirstLetterPipe } from '../../pipes/capitalize-first-letter.pipe';
// import { JobDetailsStepsHardcode } from './job-detail-steps-hardcode';
import { SplitWordPipe } from '../../pipes/split-word';
import saveAs from 'file-saver';

@Component({
  selector: 'app-job-detail-page',
  standalone: true,
  imports: [
    BreadcrumbsBarComponent,
    CapitalizeFirstLetterPipe,
    CommonModule,
    RouterModule,
    TrimFileNamePipe,
    SplitWordPipe
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  templateUrl: './job-detail-page.component.html',
  styleUrl: './job-detail-page.component.scss',
})
export class JobDetailPageComponent {
  breadcrumbs: BreadcrumbElement[] = []

  job$ = combineLatest([
    this.activatedRoute.params, 
    interval(3000)
    .pipe(startWith(0))
  ]).pipe(
    switchMap(([params, _]) => this.jobService.jobsGetById(params['id'])),
  )

  steps$ = this.job$.pipe(
    map(job => JSON.parse(job.steps)),
    // tap(parsedSteps => console.log('After parsing:', parsedSteps))
  );

  moduleTypesMap$ = this.job$.pipe(
    take(1),
    filter(job => job !== undefined && job.moduleAssignments !== undefined),
    map(job => {
      const groupedModuleTypes: Map<string, Map<string, AvlModuleVersion[]>> = new Map<string, Map<string, AvlModuleVersion[]>>();
      job.moduleAssignments.forEach(moduleAssignment => {

        if (!groupedModuleTypes.has(moduleAssignment.type)) {
          groupedModuleTypes.set(moduleAssignment.type, new Map([[moduleAssignment.name, moduleAssignment.moduleVersions]]));
        }
        else {
          const moduleVersionsMap = groupedModuleTypes.get(moduleAssignment.type);
          if (moduleVersionsMap?.has(moduleAssignment.name)) {
            moduleVersionsMap.get(moduleAssignment.name)?.push(...moduleAssignment.moduleVersions);
          } else {
            moduleVersionsMap?.set(moduleAssignment.name, moduleAssignment.moduleVersions);
          }
        }
      });
      return groupedModuleTypes;
    })
  );

  constructor(private activatedRoute: ActivatedRoute,
    private jobService: JobService) {

      this.job$.pipe(take(1))
      .subscribe(response => {
        this.breadcrumbs.push({item_name: 'Jobs',relativePathFromRoot: '/jobs'})
        this.breadcrumbs.push({item_name: response.name, relativePathFromRoot: null});
      })
  }

  onClickStartExecution(jobID: string) {
    this.jobService.jobsExecuteBuild(jobID).pipe(
      take(1)
    ).subscribe();
  }

  placeFMUFilesBeginning(file_1: AvlModuleVersion, file_2: AvlModuleVersion): number {
    if (file_1.name.endsWith('fmu') && !file_2.name.endsWith('fmu')) {
      return -1;
    } else if (!file_1.name.endsWith('fmu') && file_2.name.endsWith('fmu')) {
      return 1;
    } else {
      return 0;
    }
  }

  downloadReport(jobID: string) {
    this.jobService.jobsDownloadReport(jobID).subscribe((results: Blob) => {
      const blob = new Blob([results], { type: 'application/zip' });
      saveAs(blob, 'report.zip');
    });
  }
}
