import { Pipe, PipeTransform } from '@angular/core';
import Utils from '../utils/utils';

@Pipe({
  name: 'capitalizeFirstLetter',
  standalone: true
})
export class CapitalizeFirstLetterPipe implements PipeTransform {
  transform(value: string): string {
    return Utils.capitalizeFirstLetter(value)
  }
}
