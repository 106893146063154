<div class="grid-container">
    <!-- Welcome card -->
    <div class="grid-item full">
        <fluent-card id="dashboard-card">
            <div>
                <h1>Welcome to the AVL DevOps Pilot</h1>
                <p>Accelerating Software Deployment in the Era of Software Defined Vehicles.</p>
            </div>
            <fluent-button>Get started</fluent-button>
        </fluent-card>
    </div>

    <!-- Overview -->
    <div class="grid-item">
        <fluent-card class="card-overview">
            <div class="card-header">
                <h3>Projects</h3>
                <span>Last created 9 mins ago</span>
            </div>
            <div class="card-meta">
                <p class="heading">Total</p>
                <span class="metric">{{(meta$ | async)?.projects}}</span>
            </div>
        </fluent-card>
    </div>
    <div class="grid-item">
        <fluent-card class="card-overview">
            <div class="card-header">
                <h3>Variants</h3>
                <ng-container *ngIf="meta$ | async as meta">
                    <span>{{(meta.variants / meta.projects) | number:'1.0-0'}} in avg. per project</span>
                </ng-container>
            </div>
            <div class="card-meta">
                <p class="heading">Available</p>
                <span class="metric">{{(meta$ | async)?.variants}}</span>
            </div>
        </fluent-card>
    </div>
    <div class="grid-item">
        <fluent-card class="card-overview">
            <div class="card-header">
                <h3>Jobs</h3>
                <!-- <span>Last created 5 mins ago</span> -->
                <span>Last created {{mostRecentJobCreationTimeDiff$ | async}} ago</span>
            </div>
            <div class="card-meta">
                <p class="heading">Total</p>
                <span class="metric">{{(meta$ | async)?.jobs}}</span>
            </div>
        </fluent-card>
    </div>

    <!-- Donut Chart -->
    <div class="grid-item minor" id="donut">
        <fluent-card class="card-panel">
            <div class="card-header">
                <h3>Jobs Status</h3>
            </div>
            <div class="card-content" id="jobStatusCard">
                <canvas id="jobStatusChart">{{jobStatusChart$ | async}}</canvas>
            </div>
        </fluent-card>
    </div>

    <div class="grid-item">
        <fluent-card class="card-overview">
            <div class="card-header">
                <h3>HPCs</h3>
                <span>2/5 available</span>
            </div>
            <div class="card-meta">
                <p class="heading">Total</p>
                <span class="metric">5</span>
            </div>
        </fluent-card>
    </div>
    <div class="grid-item">
        <fluent-card class="card-overview">
            <div class="card-header">
                <h3>Users</h3>
                <span>5 active</span>
            </div>
            <div class="card-meta">
                <p class="heading">Total</p>
                <span class="metric">37</span>
            </div>
        </fluent-card>
    </div>
    <div class="grid-item">
        <fluent-card class="card-overview">
            <div class="card-header">
                <h3>Pipelines</h3>
                <span>Latest added 5 days ago</span>
            </div>
            <div class="card-meta">
                <p class="heading">Total</p>
                <span class="metric">{{(meta$ | async)?.pipelines}}</span>
            </div>
        </fluent-card>
    </div>
    <!-- Recent jobs -->
    <div class="grid-item major">
        <fluent-card class="card-panel">
            <div class="card-header">
                <h3>Recent Jobs</h3>
                <div class="card-header-recent-jobs">
                    <fluent-button class="view-all-jobs" (click)="onClickViewAllJobs()">View all Jobs</fluent-button>
                    <fluent-button class="new-job" appearance="accent" (click)="onClickNewJob()">+ New Job</fluent-button>
                </div>
            </div>
            <div class="card-content">
                <table class="recent-jobs">
                    <thead>
                        <tr>
                            <th><span>Name</span></th>
                            <th><span>Status</span></th>
                            <th><span>Project</span></th>
                            <th><span>Variant</span></th>
                            <th><span>Agent</span></th>
                        </tr>
                    </thead>
                    <tbody>
                        @for (job of recentJobDataRows$ | async; track $index) {
                            <tr>
                                <td><a [routerLink]="['/jobs/' + job.JobId]">{{job.Name}}</a></td>
                                @if((job.Status | lowercase) == "in_progress") {
                                    <td><span class="pill {{job.Status | lowercase}}">Running</span></td>
                                }
                                @else {
                                    <td><span class="pill {{job.Status | lowercase}}">{{job.Status}}</span></td>
                                }
                                <td><a [routerLink]="['/projects/' + job.ProjectId]">{{job.Project}}</a></td>
                                <td>{{job.Variant}}</td>
                                @if ((job.Status|lowercase) == 'ready' || (job.Status|lowercase) == 'queued') {
                                    <td></td>
                                }
                                @else {
                                    <td>{{job.Agent}}</td>
                                }
                            </tr>
                        }
                    </tbody>
                </table>
            </div>
        </fluent-card>
    </div>

    <!-- Graph -->
    <div class="grid-item minor">
        <fluent-card class="card-panel">
            <div class="card-header">
                <h3>Recent Job Executions</h3>
            </div>
            <div class="card-content" id="recent-job-executions-bar">
                <app-chart type="bar" [data]="latestJobExecutions$ | async"></app-chart>
            </div>
        </fluent-card>
    </div>
</div>